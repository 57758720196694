import React from "react";
import { FormControl, FormHelperText, FormLabel } from "@mui/material";
import { useFormContext, Controller } from "react-hook-form";
import "./customComponentStyle.css";

const CustomTextArea = ({
  label,
  name,
  defaultValue = "",
  width = "auto",
  height = "auto",
  borderRadius = "5px",
  borderColor = "rgb(118, 118, 118)",
  disabled = false,
  showLabel,
  padding,
  fontSize = "14px",
}) => {
  const { control } = useFormContext();
  return (
    <FormControl fullWidth style={{ marginTop: "12px" }}>
      <Controller
        name={!disabled ? `${name}_edit` : name}
        control={control}
        defaultValue={defaultValue}
        render={({ field, fieldState: { error, invalid } }) => (
          <>
            {showLabel && (
              <FormLabel
                sx={{
                  fontSize: `${fontSize} !important`,
                }}
              >
                {label}
              </FormLabel>
            )}
            <textarea
              disabled={disabled}
              {...field}
              aria-label={label}
              className={"textareaContent"}
              rows={6}
              placeholder={label}
              style={{
                width: width,
                height: height,
                borderRadius: borderRadius,
                borderColor: disabled ? "transparent" : borderColor,
                fontSize: `${fontSize} !important`,
                padding: disabled ? padding : "10px",
                background: "transparent",
                resize: "none",
                fontFamily: "Neue Montreal",
                ...(fontSize && {
                  "&::placeholder": {
                    fontSize,
                  },
                }),
              }}
            />
            {error?.message && (
              <FormHelperText error>{error?.message}</FormHelperText>
            )}
          </>
        )}
      />
    </FormControl>
  );
};

export default CustomTextArea;
