import React, { useEffect } from "react";
import {
  FormControl,
  InputLabel,
  TextField,
  Typography,
  InputAdornment,
} from "@mui/material";
import { useFormContext, Controller } from "react-hook-form";

const CustomTextField = ({
  label = "",
  name,
  edit_name,
  width = "100%",
  borderColor = "#000",
  placeholder = "",
  defaultValue = "",
  varient = "standard",
  showLabel = true,
  disabled = false,
  isPassword = false,
  prefix = "",
  varientType,
  className,
  marginTop,
  value,
}) => {
  const { control } = useFormContext();

  return (
    <FormControl
      fullWidth
      className={`${varient} ${className}`}
      sx={{ marginTop: marginTop }}
    >
      {showLabel && (
        <InputLabel sx={{ textTransform: "none !important" }}>
          {label}
        </InputLabel>
      )}
      <Controller
        name={!disabled ? edit_name ?? `${name}_edit` : name}
        control={control}
        defaultValue={defaultValue == "" ? value : defaultValue}
        render={({ field, fieldState: { error, invalid } }) => {
          return (
            <TextField
              {...field}
              type={isPassword ? "password" : "text"}
              disabled={disabled}
              helperText={error?.message}
              error={invalid}
              variant={varient}
              onChange={(e) => {
                const inputString = e.target.value;
                const numbersOnly = inputString.replace(/\D/g, "");
                const value =
                  varientType === "number" ? numbersOnly : inputString;
                field.onChange(value);
              }}
              InputProps={{
                startAdornment: (
                  <>
                    {prefix !== "" && (
                      <InputAdornment position="start">
                        <Typography
                          sx={{
                            color: "#333",
                            marginRight: "-1px",
                            fontSize: "14px",
                          }}
                        >
                          {"$"}
                        </Typography>
                      </InputAdornment>
                    )}
                  </>
                ),
              }}
              inputProps={{
                shrink: "true",
                placeholder: placeholder ? placeholder : label,
                style: {
                  borderColor: borderColor,
                  fontSize: "14px!important",
                },
              }}
              sx={{
                fontSize: `14px !important`,
                width: width,
                ".MuiInputBase-input": { fontSize: "14px!important" },
              }}
            />
          );
        }}
      />
    </FormControl>
  );
};

export default CustomTextField;
