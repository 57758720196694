import React from "react";
import { Typography, Paper, Container } from "@mui/material";

const NoResultFound = ({ message = "No listings available" }) => {
  return (
    <Container maxWidth="xl">
      {/* Set maxWidth to 'xl' for full width */}
      <Paper elevation={3} sx={{ padding: "2rem", marginTop: "1rem", textAlign: "center" }}>
        <Typography variant="h6">{message}</Typography>
        <Typography variant="body1">Check back later or try a different search.</Typography>
      </Paper>
    </Container>
  );
};

export default NoResultFound;
