import React, { useEffect } from "react";
import { Typography, Box } from "@mui/material";
import PortfolioProjects from "./PortfolioProjects";
import { getInteriorProfilePortfolio } from "../../../services/InteriorDesigner/InteriorDesignerService";

const Portfolio = ({ interiorDesignerId }) => {
  const [projects, setProjects] = React.useState({ data: [] });
  const getPortfolios = (interiorId) => {
    getInteriorProfilePortfolio(interiorId).then((res) => {
      setProjects(res.data);
    });
  };
  useEffect(() => {
    getPortfolios(interiorDesignerId);
  }, []);

  return (
    <Box
      display="flex"
      flexDirection="column"
      gap={3}
      maxWidth={"100%"}
      width={"100%"}
      paddingLeft={1}
      paddingRight={9}
    >
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h3" style={{ fontSize: "1.5rem" }}>
          Portfolio
        </Typography>
      </Box>
      {projects.data.length > 0 ? (
        <PortfolioProjects projects={projects.data} />
      ) : (
        <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          sx={{ height: "calc(100vh - 300px)" }}
        >
          <Typography variant="h3">No project yet.</Typography>
        </Box>
      )}
    </Box>
  );
};

export default Portfolio;
