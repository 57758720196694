export const removeItemAtIndex = (array, index) => {
  // Check if the index is within the valid range
  if (index >= 0 && index < array.length) {
    // Create a new array without the item at the specified index
    return [...array.slice(0, index), ...array.slice(index + 1)];
  } else {
    // If the index is invalid, return the original array
    return array;
  }
};

export const getLettersBeforeDollar = (inputString) => {
  const dollarIndex = inputString.indexOf("$");

  if (dollarIndex !== -1) {
    const lettersBeforeDollar = inputString.substring(0, dollarIndex).trim();
    return lettersBeforeDollar;
  } else {
    return inputString;
  }
};

export const addNewProp = (obj, key, value) => {
  return {
    ...obj,
    [key]: value,
  };
};

export const removePropertyFromArray = (arr, property) => {
  return arr.map((item) => {
    const { [property]: _, ...rest } = item;
    return rest;
  });
};

export const clientLeadSettingsFormInputFields = [
  {
    type: "radio",
    label: "Are you accepting virtual consultations?",
    name: "virtualConsultations.answer",
    edit_name: "virtualConsultations_edit.answer",
    options: [
      {
        label: "Yes",
        value: "Yes",
      },
      {
        label: "No",
        value: "No",
      },
    ],
    showLabel: true,
  },
  {
    name: "virtualConsultations.answer",
    dependent: "virtualConsultations.answer",
    dependent_edit: "virtualConsultations_edit.answer",
    dependentValue: "Yes",
    label: "Consultation fee.",
    label_edit: "Set you consultation fee.",
    subLabel: "Keep in mind — TALD will retain 20% of this fee.",
    type: "label",
    varientType: "number",
    nestedFields: [
      {
        name: "virtualConsultations.charge_30_mins",
        edit_name: "virtualConsultations_edit.charge_30_mins",
        showLabel: true,
        prefix: "Fee for 30 Minutes $",
        placeholder: "000",
        type: "text",
        varient: "outlined",
      },
      {
        name: "virtualConsultations.charge_60_mins",
        edit_name: "virtualConsultations_edit.charge_60_mins",
        showLabel: true,
        prefix: "Fee for 60 Minutes $",
        placeholder: "000",
        type: "text",
        varient: "outlined",
      },
    ],
  },
  {
    name: "needHelp",
    label: "How would you like to assist virtual consultation clients?",
    dependent: "virtualConsultations.answer",
    dependent_edit: "virtualConsultations_edit.answer",
    dependentValue: "Yes",
    lableMore: "Select all that apply.",
    type: "multiselect",
    options: [
      { label: "Choose Paint Colors", value: "Choose Paint Colors" },
      {
        label: "Layout / Spatial Planning",
        value: "Layout / Spatial Planning",
      },
      { label: "Discuss Where to Shop", value: "Discuss Where to Shop" },
      { label: "Making Design Decisions", value: "Making Design Decisions" },
      {
        label: "Drafting a Project Budget",
        value: "Drafting a Project Budget",
      },
      { label: "Millwork Design", value: "Millwork Design" },
      {
        label: "Fixture + Hardware Selections",
        value: "Fixture + Hardware Selections",
      },
      {
        label: "Select Furniture + Accessories",
        value: "Select Furniture + Accessories",
      },
      { label: "Flooring Advice", value: "Flooring Advice" },
      {
        label: "Solve a Design Challenge",
        value: "Solve a Design Challenge",
      },
      {
        label: "General Interior Design Advice",
        value: "General Interior Design Advice",
      },
      {
        label: "Styling Surfaces + Shelving",
        value: "Styling Surfaces + Shelving",
      },
      { label: "Hospitality Concepting", value: "Hospitality Concepting" },
      { label: "Upholstery Selections", value: "Upholstery Selections" },
    ],
    isWrap: true,
    width: "576px",
    showLabel: true,
  },
  {
    name: "fullServiceClients",
    label: "Are you accepting full-service project inquiries?",
    type: "radio",
    options: [
      {
        label: "Yes",
        value: "Yes",
      },
      {
        label: "No",
        value: "No",
      },
    ],
  },
  {
    name: "projectType",
    label: "Select which project types you’re interested in.",
    type: "multiselect",
    options: [
      {
        label: "Residential",
        value: "Residential",
      },
      {
        label: "Commercial",
        value: "Commercial",
      },
    ],
  },
  {
    type: "radio",
    label: "Are you accepting destination projects?",
    name: "destinationProject",
    edit_name: "destinationProject_edit",
    options: [
      {
        label: "Yes",
        value: "Yes",
      },
      {
        label: "No",
        value: "No",
      },
    ],
    showLabel: false,
  },
  {
    name: "projectSize",
    label: "What project types are you currently accepting?",
    lableMore: "Select all that apply.",
    type: "multiselect",
    options: [
      {
        label: "Partial Home Furnishing",
        value: "Partial Home Furnishing",
      },
      { label: "Full Home Furnishing", value: "Full Home Furnishing" },
      { label: "Partial Renovation", value: "Partial Renovation" },
      { label: "Full Renovation", value: "Full Renovation" },
      { label: "Ground-Up Development", value: "Ground-Up Development" },
      {
        label: "Full-Service Commercial Project",
        value: "Full-Service Commercial Project",
      },
    ],
    showLabel: false,
  },
  {
    name: "Range",
    label: "Set a range for all-in client budget for full-service inquiries (including fees).",
    type: "label",
    varientType: "number",
    nestedFields: [
      {
        name: "minBudget",
        edit_name: "minBudget_edit",
        prefix: "$",
        placeholder: "Average Starting Budget",
        type: "text",
        width: "177px",
        borderColor: "#9E9E9E",
        fontSize: "14px",
      },
      {
        name: "maxBudget",
        edit_name: "maxBudget_edit",
        prefix: "$",
        placeholder: "Average Top End Budget",
        type: "text",
        width: "177px",
        borderColor: "#9E9E9E",
        fontSize: "14px",
      },
    ],
  },
  {
    label: "What is your fee structure?",
    name: "feeStructure",
    type: "radio",
    options: [
      { label: "Design Fee", value: "Design Fee" },
      { label: "Design Fee + Markup", value: "Design Fee + Markup" },
      { label: "Hourly Fee", value: "Hourly Fee" },
      { label: "Hourly Fee + Markup", value: "Hourly Fee + Markup" },
      { label: "Markup Only", value: "Markup Only" },
    ],
    showLabel: false,
  },
  {
    name: "styles",
    label: "Which design genres best align with your work?",
    lableMore: "Select all that apply.",
    type: "multiselect",
    isWrap: true,
    width: "100%",
    isMultiSelect: true,
    defaultValue: [],
    options: [
      { label: "Americana", value: "Americana" },
      { label: "Maximalist", value: "Maximalist" },
      { label: "Bohemian", value: "Bohemian" },
      { label: "Mediterranean", value: "Mediterranean" },
      { label: "Coastal", value: "Coastal" },
      { label: "Mid-Century", value: "Mid-Century" },
      { label: "Colorful", value: "Colorful" },
      { label: "Minimalist", value: "Minimalist" },
      { label: "Contemporary", value: "Contemporary" },
      { label: "Modern", value: "Modern" },
      { label: "Cottagecore", value: "Cottagecore" },
      { label: "Rustic", value: "Rustic" },
      { label: "Eclectic", value: "Eclectic" },
      { label: "Scandinavian", value: "Scandinavian" },
      { label: "French Country", value: "French Country" },
      { label: "Southern", value: "Southern" },
      { label: "Historic", value: "Historic" },
      { label: "Traditional", value: "Traditional" },
      { label: "Industrial", value: "Industrial" },
      { label: "Transitional", value: "Transitional" },
    ],
    showLabel: false,
  },
  {
    name: "preferences",
    label: "Which of the following project types and lifestyle preferences best align with your work?",
    lableMore: "Select all that apply.",
    type: "multiselect",
    isWrap: true,
    width: "100%",
    isMultiSelect: true,
    defaultValue: [],
    options: [
      { label: "Art-Focused", value: "Art-Focused" },
      { label: "Layered Textures", value: "Layered Textures" },
      { label: "Beach Oasis", value: "Beach Oasis" },
      { label: "Mountain Escape", value: "Mountain Escape" },
      { label: "Chic Casita", value: "Chic Casita" },
      { label: "Neutral Tones", value: "Neutral Tones" },
      { label: "City Living", value: "City Living" },
      { label: "No Vintage Please", value: "No Vintage Please" },
      { label: "Earth Tones", value: "Earth Tones" },
      { label: "Open Concept", value: "Open Concept" },
      { label: "Eco-friendly", value: "Eco-friendly" },
      { label: "Pet Proof", value: "Pet Proof" },
      { label: "Family Friendly", value: "Family Friendly" },
      { label: "Prints + Patterns", value: "Prints + Patterns" },
      { label: "Farm House", value: "Farm House" },
      { label: "Sustainably Sourced", value: "Sustainably Sourced" },
      { label: "Global Influence", value: "Global Influence" },
      { label: "Tone on Tone", value: "Tone on Tone" },
      { label: "Historic Revival", value: "Historic Revival" },
      { label: "Vintage Elements", value: "Vintage Elements" },
      { label: "Lake Getaway", value: "Lake Getaway" },
      { label: "Wellness-Focused", value: "Wellness-Focused" },
    ],
    showLabel: false,
  },
];
export const editProjectFields = [
  {
    type: "image",
    label: "Edit Cover",
    color: "rgba(0, 0, 0, 0.87)",
    name: "cover",
    varient: "outlined",
    frontType: "image",
    width: "160px",
    height: "160px",
    isMultiSelect: false,
  },
  {
    type: "text",
    showLabel: false,
    placeholder: "Name your project",
    name: "name",
    width: "100%",
  },
  {
    type: "text",
    showLabel: false,
    placeholder: "Photography credits",
    name: "credits",
    width: "100%",
    marginTop: "30px",
  },
  {
    type: "image",
    label: "Add Pictures",
    color: "rgba(0, 0, 0, 0.87)",
    name: "pictures",
    varient: "outlined",
    frontType: "image",
    width: "160px",
    height: "160px",
    marginTop: "30px",
    isMultiSelect: true,
  },
];
export const getNickName = (name) => {
  if (!name) return '';
  const words = name.trim().split(" ");
  let nickname = "";

  for (let i = 0; i < words.length; i++) {
    nickname += words[i][0];
  }

  return nickname.toUpperCase();
};
