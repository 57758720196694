import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import {
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel,
  FormLabel,
  FormHelperText,
  FormGroup,
  Typography,
} from "@mui/material";

const CustomRadioGroup = ({
  label,
  lableMore,
  options = [],
  name,
  isWrap,
  dependent,
  dependent_edit,
  dependentValue,
  edit_name,
  defaultValue,
  disabled = false,
  isMultiSelect,
  fontSize = "14px",
}) => {
  const { control, setValue, watch } = useFormContext();
  const dependentKey = disabled ? dependent : dependent_edit;
  const watchedDependency = dependentKey ? watch(dependentKey) : null;
  if (watchedDependency && watchedDependency !== dependentValue) return <></>;
  return (
    <FormControl component="fieldset">
      <FormLabel component="legend">
        <Typography sx={{ fontSize: `${fontSize} !important` }}>
          {label}
        </Typography>
      </FormLabel>
      {isMultiSelect ? (
        <Controller
          name={!disabled ? edit_name ?? `${name}_edit` : name}
          control={control}
          defaultValue={defaultValue || []}
          render={({ field, fieldState: { error, invalid } }) => (
            <>
              <div className="font-italic black-secondary-color">
                <Typography sx={{ fontSize: `${fontSize} !important` }}>
                  {lableMore}
                </Typography>
              </div>
              <FormGroup>
                {options &&
                  options.map((option) => (
                    <FormControlLabel
                      key={typeof option === "string" ? option : option.value}
                      disabled={disabled}
                      style={{
                        marginRight: "0px !important",
                        fontSize: `${fontSize} !important`,
                      }}
                      sx={{
                        "& .MuiFormControlLabel-label": {
                          fontSize: `${fontSize} !important`,
                        },
                      }}
                      control={
                        <Radio
                          checked={field.value?.includes(
                            typeof option === "string" ? option : option.value
                          )}
                          onChange={() => {
                            const optionValue =
                              typeof option === "string"
                                ? option
                                : option.value;
                            const updatedValue = field.value?.includes(
                              optionValue
                            )
                              ? field.value.filter(
                                  (value) => value !== optionValue
                                )
                              : [...field.value, optionValue];
                            field.onChange(updatedValue);
                          }}
                          sx={{
                            fontSize: `${fontSize} !important`,
                            "& .MuiSvgIcon-root": {
                              borderRadius: "50%",
                              width: "12px",
                              height: "12px",
                              padding: "6px",
                              border: "1px solid #333",
                              backgroundColor: field.value.includes(
                                typeof option === "string"
                                  ? option
                                  : option.value
                              )
                                ? "#333"
                                : "transparent",
                              "& + svg": {
                                display: "none",
                              },
                            },
                          }}
                        />
                      }
                      label={typeof option === "string" ? option : option.label}
                    />
                  ))}
              </FormGroup>
              {invalid && (
                <FormHelperText error>{error?.message}</FormHelperText>
              )}
            </>
          )}
        />
      ) : (
        <Controller
          name={!disabled ? edit_name ?? `${name}_edit` : name}
          control={control}
          defaultValue={defaultValue || ""}
          render={({ field, fieldState: { error, invalid } }) => (
            <>
              <RadioGroup
                {...field}
                sx={{
                  paddingLeft: "10px",
                  display: isWrap ? "flex" : "block",
                  flexWrap: "wrap",
                  flexDirection: "row",
                }}
              >
                {options &&
                  options.map((option, key) => (
                    <FormControlLabel
                      key={key}
                      value={typeof option === "string" ? option : option.value}
                      style={{
                        marginRight: "0px !important",
                        marginBottom: "-5px",
                        width: isWrap ? "45%" : "100%",
                        fontSize: `${fontSize} !important`,
                      }}
                      sx={{
                        marginLeft: "0px !important",
                        marginBottom: "0px !important",
                        "& .MuiFormControlLabel-label": {
                          fontSize: `14px !important`,
                        },
                      }}
                      control={
                        <Radio
                          disabled={disabled}
                          sx={{
                            fontSize: `${fontSize} !important`,
                            "& .MuiSvgIcon-root": {
                              borderRadius: "50%",
                              width: "12px",
                              height: "12px",
                              padding: "6px",
                              border: "1px solid #333",
                              backgroundColor:
                                typeof field.value === "string"
                                  ? field.value ===
                                    (typeof option === "string"
                                      ? option
                                      : option.value)
                                    ? "#333"
                                    : "transparent"
                                  : field.value.includes(
                                      typeof option === "string"
                                        ? option
                                        : option.value
                                    )
                                  ? "#333"
                                  : "transparent",
                              "& + svg": {
                                display: "none",
                              },
                            },
                          }}
                          onChange={(e) => {
                            setValue(field.name, e.target.value);
                          }}
                        />
                      }
                      label={typeof option === "string" ? option : option.label}
                    />
                  ))}
              </RadioGroup>
              {invalid && (
                <FormHelperText error>{error?.message}</FormHelperText>
              )}
            </>
          )}
        />
      )}
    </FormControl>
  );
};

export default CustomRadioGroup;
