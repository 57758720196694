export const GET_LIST = "admin/vendorList";
export const CREATE_VENDOR = "admin/createVendor";
export const RESEND_TEMP_PASSWORD = "admin/resendTempPassword";
export const GET_CONSULTATION_DETAILS = "/admin/getConsultationsByDesignerId";

export const USERS_ACTION = "admin/userAction";
export const GET_PROFILE = "admin/getProfile";
export const GET_INTERIOR_DESIGNER_PROFILE = "admin/getInteriorDesignerProfile";
export const UPDATE_VENDOR_PROFILE = "/vendor/editVendorProfileByAdmin";
export const GET_VENDOR_PORTFOLIO = "/vendor/getPortfolioByAdmin";
