import React from "react";
import { Box, Typography, Chip } from "@mui/material";
import moment from "moment";

const ConfirmedCard = ({ confirmed, refresh, isPast = false }) => {
    return (
        <Box display="flex" flexDirection={"column"} gap={3} sx={{ pt: 1, pb: 6, borderBottom: "1px solid #9E9E9E", width: '100%' }}>
            <Typography variant="h3" sx={{ fontSize: '28px' }}>{confirmed?.user?.name}</Typography>
            <Box display="flex" flexDirection={"column"} gap={3}>
                <Box display="flex" flexDirection={"column"} gap={2}>
                    <Box display="flex" flexDirection={"row"} gap={1}>
                        <Typography variant="body2">Selected Times</Typography>
                        <Typography variant="body2" color="#9E9E9E">{`(${confirmed.timeZone})`}</Typography>
                    </Box>
                    <Box display="flex" flexDirection={"row"} gap={1} flexWrap={"wrap"}>
                        <>
                            {confirmed.timeSlots.map(({ startTime, endTime }, i) => {
                                return (
                                    <Chip
                                        key={i}
                                        variant="outlined"
                                        label={`${moment(startTime).format("dddd, MMMM DD, YYYY")} (${moment(startTime).format(
                                            "hh:mm A"
                                        )} - ${moment(endTime).format("hh:mm A")})`}
                                        sx={{
                                            backgroundColor: "#F1F0EE",
                                            border: "none!important",
                                            color: "black!important",
                                            py: "6px!important",
                                            fontSize: "12px!important",
                                        }}
                                    />
                                );
                            })}
                        </>
                    </Box>
                </Box>
                <Box display="flex" flexDirection={"column"} gap={2}>
                    <Typography variant="body2">Overview</Typography>
                    <Typography variant="body2" color="#9E9E9E">
                        {confirmed.projectSummary}
                    </Typography>
                </Box>
            </Box>
        </Box>
    );
};

export default ConfirmedCard;
