import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { Slider, Typography, Grid, FormHelperText } from "@mui/material";

const CustomRangeSlider = ({
  label,
  name,
  min,
  max,
  defaultValue = [2000, 5000000], // Default range values
  step = 10000,
  disabled = false,
}) => {
  const { control } = useFormContext();

  return (
    <div className="customRangeSlider w-100">
      <Typography id={`${name}-slider`} gutterBottom>
        {label}
      </Typography>
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        render={({ field, fieldState: { error } }) => (
          <>
            <Grid container justifyContent="space-between">
              <Typography>${min}</Typography>
              <Typography>${max}+</Typography>
            </Grid>
            <Slider
              disabled={disabled}
              {...field}
              valueLabelDisplay="auto"
              step={step}
              marks
              min={min}
              max={max}
              value={field.value}
              onChange={(e, newValue) => {
                // Ensure newValue is an array
                if (Array.isArray(newValue)) {
                  field.onChange(newValue);
                }
              }}
            />
            {error?.message && (
              <FormHelperText error>{error?.message}</FormHelperText>
            )}
          </>
        )}
      />
    </div>
  );
};

export default CustomRangeSlider;
