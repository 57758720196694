import React, { useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import {
  TextField,
  FormControl,
  FormHelperText,
  Autocomplete,
  Box,
  FormLabel,
} from "@mui/material";
import PlacesAutocomplete from "react-places-autocomplete";

const GoogleAutoComplete = ({
  name,
  className,
  label,
  borderRadius,
  varient,
  disabled = false,
  edit_name,
  padding = 16,
  endAdornment = 14,
}) => {
  const { control, watch } = useFormContext();
  const [address, setAddress] = useState("");
  const defaultAddress = watch("location");
  const handleSelect = async (field, selectedAddress) => {
    try {
      setAddress(selectedAddress);
      field.onChange(selectedAddress);
    } catch (error) {
      console.error("Error selecting address:", error);
    }
  };

  return (
    <FormControl className={className} sx={{ minWidth: "250px" }}>
      {label && (
        <Box>
          <FormLabel>{label}</FormLabel>
        </Box>
      )}
      <Controller
        name={!disabled ? edit_name ?? `${name}_edit` : name}
        control={control}
        render={({ field, fieldState: { error, invalid } }) => {
          return (
            <>
              <PlacesAutocomplete
                value={address}
                onChange={setAddress}
                onSelect={(address) => {
                  handleSelect(field, address);
                }}
              >
                {({ getInputProps, suggestions, loading }) => (
                  <>
                    <Autocomplete
                      disabled={disabled}
                      loading={loading}
                      options={suggestions.map(
                        (suggestion) => suggestion.description
                      )}
                      value={field.value}
                      defaultValue={defaultAddress}
                      onChange={(_, value) => handleSelect(field, value || "")}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          {...getInputProps({
                            placeholder: "Location",
                            className: "location-search-input",
                          })}
                          variant={varient}
                          size="small"
                          sx={{
                            "& .MuiAutocomplete-inputRoot": {
                              borderRadius: borderRadius,
                              padding: `0px ${padding}px !important`,
                              height: "34px !important",
                              fontSize: "14px !important",
                              color: "#333333 !important",
                            },
                            "& .MuiAutocomplete-endAdornment": {
                              top: `calc(50% - ${endAdornment}px) !important`,
                              right: "2px !important",
                            },
                          }}
                        />
                      )}
                    />
                  </>
                )}
              </PlacesAutocomplete>
              {invalid && (
                <FormHelperText sx={{ color: "#d32f2f", ml: 0 }}>
                  {error?.message}
                </FormHelperText>
              )}
            </>
          );
        }}
      />
    </FormControl>
  );
};

export default GoogleAutoComplete;
