import instance from "../Instance";
import {
  GET_METADESCRIPTIONS,
  CREATE_METADESCRIPTION,
  UPDATE_METADESCRIPTION,
  DELETE_METADESCRIPTION,
} from "./ApiEndpoints";

export function getMetadescriptions(currentPage, limit, search = "") {
  const data = localStorage.getItem("taldDetails");
  const myHeaders = {
    Authorization: `Bearer ${data}`,
  };

  return instance.get(
    `${GET_METADESCRIPTIONS}?page=${currentPage}&limit=${limit}&search=${search}`,
    {
      headers: myHeaders,
    }
  );
}

export function createMetadescription(metaDescription, userId) {
  const data = localStorage.getItem("taldDetails");
  const myHeaders = {
    Authorization: `Bearer ${data}`,
  };

  return instance.post(
    CREATE_METADESCRIPTION,
    { metaDescription, userId },
    {
      headers: myHeaders,
    }
  );
}

export function updateMetadescription(metaDescription, userId) {
  const data = localStorage.getItem("taldDetails");
  const myHeaders = {
    Authorization: `Bearer ${data}`,
  };

  return instance.patch(
    UPDATE_METADESCRIPTION,
    { metaDescription, userId },
    {
      headers: myHeaders,
    }
  );
}

export function deleteMetadescription(userId) {
  const data = localStorage.getItem("taldDetails");
  const myHeaders = {
    Authorization: `Bearer ${data}`,
  };

  return instance.delete(DELETE_METADESCRIPTION, {
    data: { userId },
    headers: myHeaders,
  });
}
