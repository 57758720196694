import instance from "../Instance";
import {
  GET_COUPONS_LIST,
  CREATE_COUPON,
  UPDATE_COUPON,
  DELETE_COUPON,
  COUPON_ACTION,
} from "./ApiEndpoints";

export function getCouponsList(currentPage, limit, search) {
  const data = localStorage.getItem("taldDetails");
  const myHeaders = {
    Authorization: `Bearer ${data}`,
  };
  return instance.get(
    GET_COUPONS_LIST + `?page=${currentPage}&limit=${limit}&search=${search}`,
    {
      headers: myHeaders,
    }
  );
}

export function createCoupon({ name, discount }) {
  const data = localStorage.getItem("taldDetails");
  const myHeaders = {
    Authorization: `Bearer ${data}`,
  };

  return instance.post(
    CREATE_COUPON,
    { name, discount },
    {
      headers: myHeaders,
    }
  );
}

export function updateCoupon({ couponId, name }) {
  const data = localStorage.getItem("taldDetails");
  const myHeaders = {
    Authorization: `Bearer ${data}`,
  };
  return instance.post(
    UPDATE_COUPON,
    { couponId, name },
    {
      headers: myHeaders,
    }
  );
}

export function deleteCoupon(couponId) {
  const data = localStorage.getItem("taldDetails");
  const myHeaders = {
    Authorization: `Bearer ${data}`,
  };
  return instance.post(
    DELETE_COUPON,
    { couponId },
    {
      headers: myHeaders,
    }
  );
}

export function couponAction(couponId, action) {
  const data = localStorage.getItem("taldDetails");
  const myHeaders = {
    Authorization: `Bearer ${data}`,
  };

  return instance.post(
    COUPON_ACTION,
    { couponId, action },
    {
      headers: myHeaders,
    }
  );
}
