import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import {
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Checkbox,
  Typography,
} from "@mui/material";

const CustomCheckboxGroup = ({
  width,
  label,
  lableMore,
  dependent,
  dependent_edit,
  dependentValue,
  isWrap,
  options,
  name,
  fontSize = "14px",
  defaultValue = [],
  disabled,
}) => {
  const { control, formState: { errors } = {}, watch } = useFormContext();
  const dependentKey = disabled ? dependent : dependent_edit;
  const watchedDependency = dependentKey ? watch(dependentKey) : null;
  if (watchedDependency && watchedDependency !== dependentValue) return <></>;
  return (
    <FormGroup sx={{ width: width ? width : "100%" }}>
      <Typography sx={{ textAlign: "left", fontSize: `14px !important` }}>
        {label}
      </Typography>
      <Controller
        name={!disabled ? `${name}_edit` : name}
        control={control}
        defaultValue={defaultValue}
        render={({ field, fieldState: { error, invalid } }) => (
          <>
            {!disabled && (
              <Typography
                className="black-secondary-color"
                sx={{
                  textAlign: "left",
                  fontSize: "14px!important",
                  marginTop: "5px",
                }}
              >
                {lableMore}
              </Typography>
            )}
            <FormGroup
              sx={{
                marginLeft: "22px",
                display: isWrap ? "flex" : "block",
                flexWrap: "wrap",
                flexDirection: "row",
              }}
            >
              {options &&
                options.map((option) => (
                  <FormControlLabel
                    key={typeof option === "string" ? option : option.value}
                    disabled={disabled}
                    style={{
                      marginRight: "0px !important",
                      marginBottom: "-5px",
                      width: isWrap ? "45%" : "100%",
                      fontSize: `${fontSize} !important`,
                    }}
                    sx={{
                      "& .MuiFormControlLabel-label": {
                        fontSize: `${fontSize} !important`,
                      },
                    }}
                    control={
                      <Checkbox
                        checked={field.value.includes(
                          typeof option === "string" ? option : option.value
                        )}
                        onChange={(e) => {
                          const optionValue =
                            typeof option === "string" ? option : option.value;
                          const updatedValue = field.value.includes(optionValue)
                            ? field.value.filter(
                                (value) => value !== optionValue
                              )
                            : [...field.value, optionValue];
                          field.onChange(updatedValue);
                        }}
                        sx={{
                          "& .MuiSvgIcon-root": {
                            borderRadius: "50%",
                            width: "12px",
                            height: "12px",
                            padding: "6px",
                            border: "1px solid #333",
                            backgroundColor: field.value.includes(
                              typeof option === "string" ? option : option.value
                            )
                              ? "#333"
                              : "transparent",
                          },
                        }}
                      />
                    }
                    label={typeof option === "string" ? option : option.label}
                  />
                ))}
            </FormGroup>
            {invalid && <FormHelperText error>{error?.message}</FormHelperText>}
          </>
        )}
      />
    </FormGroup>
  );
};

export default CustomCheckboxGroup;
