import React, { useState } from "react";
import { Box, Typography, Tooltip } from "@mui/material";
import {
  editProjectFields,
  addNewProp,
  removePropertyFromArray,
} from "../../../lib/common";
import InputFieldProvider from "../../components/common/InputFieldProvider";
import CustomDialog from "../../components/Modal/CustomDialog";
import { FormProvider, useForm, SubmitHandler } from "react-hook-form";
import "./editProjectStyle.css";

const EditProject = ({ project }) => {
  const [openUpdateDlg, setOpenUpdateDlg] = useState(false);
  const [items, setItems] = useState();
  const methods = useForm();
  const { setValue } = methods;
  const onInit = () => {
    setItems(removePropertyFromArray(project?.itemImages ?? [], "_id"));
    setOpenUpdateDlg(false);
  };
  const UpdateContent = ({ project }) => {
    const editInputFields = [
      addNewProp(editProjectFields[0], "value", project?.coverImage.image),
      addNewProp(editProjectFields[1], "value", project?.projectName),
      addNewProp(editProjectFields[2], "value", project?.credits),
      addNewProp(editProjectFields[3], "value", project?.itemImages),
    ];
    if (!project) return null;

    const projectFields = editInputFields;
    if (project) {
      setValue("name_edit", project.projectName);
      setValue("credits_edit", project.credits);
    }
    return (
      <>
        <div className="portfolioPopupFields">
          <FormProvider {...methods}>
            {projectFields.map((field, key) => {
              const props = {
                items,
                setItems,
                ...field,
              };

              return (
                <React.Fragment
                  key={openUpdateDlg ? `${key}_opened` : `${key}_closed`}
                >
                  <InputFieldProvider {...props} />
                </React.Fragment>
              );
            })}
          </FormProvider>
        </div>
      </>
    );
  };

  return (
    <React.Fragment>
      <Box
        sx={{
          width: "242px",
        }}
      >
        {project?.coverImage && (
          <Box
            className="imageContainer"
            position="relative"
            onClick={() => setOpenUpdateDlg(true)}
            sx={{
              "&:hover": {
                cursor: "pointer",
              },
              width: "242px",
              height: "242px",
            }}
          >
            <img
              src={project.coverImage.image}
              alt="portfolio Image"
              width={242}
              height={242}
              className={"portfolioImage"}
              style={{
                objectFit: "cover",
                height: "100%",
                borderRadius: "5px",
              }}
            />
          </Box>
        )}
        <Box>
          <Tooltip title={project?.projectName}>
            <Typography
              variant="h3"
              sx={{
                textTransform: "capitalize",
                wordBreak: "keep-all",
                mt: 2,
                textWrap: "nowrap",
                textOverflow: "ellipsis",
                overflow: "hidden",
                width: "242px",
                fontSize: "1.5rem",
              }}
            >
              {project?.projectName}
            </Typography>
          </Tooltip>
          <Typography
            sx={{
              marginTop: "8px",
              fontFamily: "Neue Montreal !important",
              fontSize: "14px !important",
              fontWeight: "400 !important",
              fontStyle: "normal !important",
              lineHeight: "18.2px !important",
              letterSpacing: "2% !important",
              color: "#9E9E9E",
            }}
          >
            {project?.itemImages.length} items
          </Typography>
        </Box>
      </Box>
      <CustomDialog
        title={"Project Details"}
        open={openUpdateDlg}
        onClose={() => onInit()}
        content={<UpdateContent project={project} />}
      />
    </React.Fragment>
  );
};

export default EditProject;
