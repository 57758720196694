import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  FormHelperText,
} from "@mui/material";

const CustomSelect = ({ label, width, options, name, defaultValue }) => {
  const { control } = useFormContext();

  return (
    <FormControl sx={{ width: width ? width : "100%" }}>
      <InputLabel>{label}</InputLabel>
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue || ""}
        render={({ field, fieldState: { error, invalid } }) => (
          <>
            <Select {...field}>
              {options &&
                options.map((option) =>
                  typeof option === "string" ? (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ) : (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  )
                )}
            </Select>
            {invalid && <FormHelperText>{error?.message}</FormHelperText>}
          </>
        )}
      />
    </FormControl>
  );
};

export default CustomSelect;
