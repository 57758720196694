import React from "react";
import { Box } from "@mui/material";
import NoResultFound from "../NoResultFound";
import ConfirmedCard from "./ConfirmedCard";

const ConfirmedConsultations = ({ confirmedConsultations }) => {
    return (
        <Box display="flex" flexDirection="column" gap={4} sx={{
            width: '100%'
        }}>
            {confirmedConsultations?.value && confirmedConsultations?.value.length > 0 ? (
                confirmedConsultations?.value.map((confirmed, key) => <ConfirmedCard key={key} confirmed={confirmed} />)
            ) : (
                <NoResultFound message="No confirmed consultations" />
            )}
            {/* Transaction receipt */}
        </Box>
    );
};

export default ConfirmedConsultations;
