import React, { useEffect, useState } from "react";
import { Card, Col, Dropdown, Table } from "react-bootstrap";
import PageTitle from "../layouts/PageTitle";
import Spinner from "../components/common/Spinner";
import { getMetadescriptions } from "../../services/MetaDescription/MetaDescriptionService";
import Pagination from "../components/common/Pagination";
import { toast, ToastContainer } from "react-toastify";
import "./interiorDesignerManagement.css";
import AddMetaDescriptionModal from "./AddMetaDescriptionModal";

export default function MetaDescriptionManagement(props) {
  const svg1 = (
    <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <rect x="0" y="0" width="24" height="24"></rect>
        <circle fill="#000000" cx="5" cy="12" r="2"></circle>
        <circle fill="#000000" cx="12" cy="12" r="2"></circle>
        <circle fill="#000000" cx="19" cy="12" r="2"></circle>
      </g>
    </svg>
  );

  const notifyError = (error) => {
    toast.error(`❌${error}`, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const [loader, setLoader] = useState(false);
  const [metadescriptions, setMetadescriptions] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [search, setSearch] = useState("");
  const [debouncedSearch, setDebouncedSearch] = useState("");
  const limit = 10;
  const [listLength, setListLength] = useState("");
  const [addModal, setAddModal] = useState({ show: false, meta: null });

  function getTableData() {
    setLoader(true);
    getMetadescriptions(currentPage, limit, debouncedSearch)
      .then((response) => {
        setMetadescriptions(response.data.data.users);
        const total = response.data.data.total;
        setLoader(false);
        setPageCount(Math.ceil(total / limit));
        setListLength(total);
      })
      .catch((error) => {
        console.log(error.response);
        notifyError("Error fetching metadescriptions");
        setLoader(false);
      });
  }

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearch(search);
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [search]);

  useEffect(() => {
    getTableData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, debouncedSearch]);

  return (
    <div>
      <ToastContainer />
      <PageTitle
        activeMenu="Meta Descriptions"
        motherMenu="Content Management"
      />
      <Col>
        <Card>
          <Card.Header className="">
            <div className="row d-flex justify-content-end">
              <div className="col-4" style={{ flexGrow: 1 }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div
                    className="input-group border bg-white input-group-sm"
                    style={{ borderRadius: "8px" }}
                  >
                    <input
                      style={{
                        paddingBottom: "25px",
                        paddingTop: "25px",
                        borderRadius: "10px",
                        fontSize: "14px",
                      }}
                      type="text"
                      name="table_search"
                      className="form-control float-right border-0"
                      placeholder="Search"
                      onChange={(e) => setSearch(e.target.value)}
                    />
                    <div className="input-group-append">
                      <button type="button" className="btn btn-default">
                        <i className="fa fa-search" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Card.Header>
          <Card.Body>
            <Table responsive>
              <thead style={{ color: "black" }}>
                <tr>
                  <th>
                    <strong>NAME</strong>
                  </th>
                  <th>
                    <strong>EMAIL</strong>
                  </th>
                  <th>
                    <strong>METADESCRIPTION</strong>
                  </th>
                  <th>
                    <strong>ACTION</strong>
                  </th>
                </tr>
              </thead>
              <tbody>
                {metadescriptions?.map((item, index) => (
                  <tr key={index}>
                    <td style={{ textTransform: "capitalize" }}>{item.name}</td>
                    <td>{item.email}</td>
                    <td>{item.metaDescription}</td>
                    <td>
                      <Dropdown>
                        <Dropdown.Toggle
                          variant="secondary light"
                          className="light sharp btn btn-secondary"
                        >
                          {svg1}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item
                            onClick={() =>
                              setAddModal({ show: true, meta: item })
                            }
                          >
                            Edit
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            {metadescriptions?.length === 0 && !loader ? (
              <div className="justify-content-center d-flex my-5">
                Sorry, Data Not Found!
              </div>
            ) : (
              ""
            )}
            {metadescriptions?.length > 0 && (
              <div className="d-flex justify-content-between align-items-center">
                <div className="dataTables_info">
                  Showing {currentPage * limit + 1} to{" "}
                  {listLength > (currentPage + 1) * limit
                    ? (currentPage + 1) * limit
                    : listLength}{" "}
                  of {listLength} entries
                </div>
                <Pagination
                  pageCount={pageCount}
                  pageValue={currentPage}
                  setPage={setCurrentPage}
                />
              </div>
            )}
          </Card.Body>
        </Card>
      </Col>
      <AddMetaDescriptionModal
        show={addModal.show}
        meta={addModal.meta}
        table={getTableData}
        onHide={() => setAddModal({ show: false, meta: null })}
      />
      {loader && <Spinner />}
    </div>
  );
}
