import React, { useEffect, useState } from "react";
import { Badge, Card, Col, Dropdown, Table } from "react-bootstrap";
import {
  getAllUsers,
  getRequest,
  getVirtualConsultant,
} from "../../services/AuthService";
import PageTitle from "../layouts/PageTitle";
import Spinner from "../components/common/Spinner";
import Pagination from "../components/common/Pagination";
import moment from "moment";
import { approveUser } from "../../services/Requests/RequestService";
import { toast, ToastContainer } from "react-toastify";

export default function Request(props) {
  const svg1 = (
    <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <rect x="0" y="0" width="24" height="24"></rect>
        <circle fill="#000000" cx="5" cy="12" r="2"></circle>
        <circle fill="#000000" cx="12" cy="12" r="2"></circle>
        <circle fill="#000000" cx="19" cy="12" r="2"></circle>
      </g>
    </svg>
  );
  const [loader, setLoader] = useState(false);
  const [users, setUsers] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [search, setSearch] = useState("");

  const limit = 10;
  const notifyTopRight = (success) => {
    toast.success(`✅ ${success}`, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  };
  const notifyError = (error) => {
    toast.error(`❌${error}`, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  function getTableData() {
    setLoader(true);
    getRequest(currentPage, limit, search)
      .then((response) => {
        setUsers(response.data.data?.request);
        const total = response.data.data.total;
        setLoader(false);
        setPageCount(Math.ceil(total / limit));

        console.log(response.data, " table data rrrrrr ");
      })
      .catch((error) => {
        console.log(error.response, "helooooooooo");
        if (error.response.data.statusCode === 401) {
          localStorage.clear("taldDetails");
          props.history.push("/login");
        }
      });
  }
  function onAction(userId, status) {
    setLoader(true);
    approveUser(userId, status)
      .then((response) => {
        console.log(response, "api response");
        {
          status
            ? notifyTopRight("Approved Successfully!")
            : notifyTopRight("Pending Successfully!");
        }

        setLoader(false);
        getTableData();
      })
      .catch((error) => {
        setLoader(false);
        notifyError(error.response?.data?.message);
        console.log(error.response, "helooooooooo");
        if (error.response?.data?.statusCode === 401) {
          localStorage.clear("taldDetails");
          props.history.push("/login");
        }
      });
  }
  useEffect(() => {
    getTableData();
  }, [currentPage, search]);
  return (
    <div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <PageTitle activeMenu="Request List" motherMenu="Request" />
      <Col>
        <Card>
          <Card.Header className="">
            <div className="row d-flex justify-content-end ">
              <div className="col-4" style={{ flexGrow: 1 }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div
                    className="input-group border bg-white input-group-sm"
                    style={{ borderRadius: "8px" }}
                  >
                    <input
                      style={{
                        paddingBottom: "25px",
                        paddingTop: "25px",
                        borderRadius: "10px",
                        fontSize: "14px",
                      }}
                      type="text"
                      name="table_search"
                      className="form-control float-right border-0"
                      placeholder="Search"
                      onChange={(e) => setSearch(e.target.value)}
                    />
                    <div className="input-group-append">
                      <button
                        type="button"
                        className="btn btn-default"
                        // onClick={getTableData}
                      >
                        <i className="fa fa-search" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="mr-2">
                <Link
                  variant="primary"
                  type="button"
                  className="btn btn-color px-2 py-2 text-white"
                  onClick={() => setAddModal(true)}
                >
                  Add New +
                </Link>
              </div> */}
            </div>
          </Card.Header>
          <Card.Body>
            <Table responsive>
              <thead style={{ color: "black" }}>
                <tr>
                  <th>
                    <strong>DATE</strong>
                  </th>
                  <th>
                    <strong> MEMBER NAME</strong>
                  </th>
                  <th>
                    <strong>EMAIL</strong>
                  </th>
                  <th>
                    <strong>STATUS</strong>
                  </th>
                  <th>
                    <strong>ACTION</strong>
                  </th>
                </tr>
              </thead>
              <tbody>
                {users?.map((item) => (
                  <tr>
                    <td>{moment(item.createdAt).format("MM/DD/YYYY")}</td>
                    <td>{item?.sender?.name}</td>
                    <td>{item?.sender?.name}</td>

                    <td>
                      {item?.status ? (
                        <Badge variant="success light "> Approved</Badge>
                      ) : (
                        <Badge variant="danger light "> Pending</Badge>
                      )}
                    </td>
                    <td>
                      <Dropdown>
                        <Dropdown.Toggle
                          variant="secondary light"
                          className="light sharp btn btn-secondary i-false"
                        >
                          {svg1}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          {item.status ? (
                            <Dropdown.Item
                              onClick={() => onAction(item._id, false)}
                            >
                              Pending
                            </Dropdown.Item>
                          ) : (
                            <Dropdown.Item
                              onClick={() => onAction(item._id, true)}
                            >
                              Approved
                            </Dropdown.Item>
                          )}
                        </Dropdown.Menu>
                      </Dropdown>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            {users?.length === 0 && !loader ? (
              <div className="justify-content-center d-flex my-5 ">
                Sorry, Data Not Found!
              </div>
            ) : (
              ""
            )}
            <Pagination
              pageCount={pageCount}
              pageValue={currentPage}
              setPage={setCurrentPage}
            />
          </Card.Body>
        </Card>
      </Col>
      {loader && <Spinner />}
    </div>
  );
}
