import React, { useRef } from "react";
import { useFormContext, Controller } from "react-hook-form";
import {
  FormControl,
  FormHelperText,
  InputLabel,
  Typography,
  Box,
  Button,
} from "@mui/material";
import UploadIcon from "../../../images/svg/upload-icon.svg";
import AddImage from "../../../images/svg/add-image.svg";
import EditIcon from "../../../images/svg/edit-icon.svg";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { removeItemAtIndex } from "../../../lib/common";
import toast from "react-hot-toast";

import "./customComponentStyle.css";
const MAX_FILE_NUMBER = 1000;

const defaultAcceptedFormats = [
  "image/jpeg",
  "image/jpg",
  "image/png",
  "application/pdf",
];

const CustomFileSelect = ({
  label,
  color,
  name,
  value,
  acceptedFormats = defaultAcceptedFormats,
  isMultiSelect,
  frontType,
  width,
  marginTop,
  items,
  setItems,
}) => {
  const inputRef = (useRef < HTMLInputElement) | (null > null);
  const { control, getValues, setValue } = useFormContext();

  const addOrEditImg = () => {
    // Trigger the file input click event when the div is clicked
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  const removeImg = (fileName) => {
    const currentFiles = getValues(name) || [];
    const updatedFiles = currentFiles.filter((file) => file.name !== fileName);
    setValue(name, updatedFiles);
  };

  const removeItemsImg = (key) => {
    const filterImgs = removeItemAtIndex(items ?? [], key);
    if (setItems) {
      setItems(filterImgs);
    }
  };

  const acceptedString = acceptedFormats.map((format) =>
    format.split("/").pop()
  );

  return (
    <div className="customFileWrap w-100" style={{ marginTop: marginTop }}>
      <FormControl className="outlined" fullWidth>
        <InputLabel sx={{ color: color }}>{label}</InputLabel>
        <Controller
          name={name}
          control={control}
          render={({ field, fieldState: { error, invalid } }) => {
            const itemsNum =
              (field.value ? field.value?.length : 0) +
              (value ? value.length : 0);
            return (
              <>
                <div
                  className="uploadFilesField"
                  style={{
                    width: width,
                    paddingLeft: isMultiSelect ? "5px" : "0px",
                    paddingRight: isMultiSelect ? "5px" : "0px",
                    margin: isMultiSelect ? "auto" : "0px",
                  }}
                >
                  {typeof value === "string" && value && !field.value && (
                    <Box
                      width="100% !important"
                      height="100%"
                      display="flex"
                      flexWrap="wrap"
                      justifyContent="start"
                      gap={2}
                      sx={{
                        opacity: isMultiSelect ? "1" : "0.5",
                        overflow: isMultiSelect ? "auto" : "hidden",
                        paddingTop: isMultiSelect ? "5px" : "",
                        paddingBottom: isMultiSelect ? "5px" : "",
                      }}
                    >
                      <Box
                        sx={{
                          position: "relative",
                          width: isMultiSelect
                            ? "178px !important"
                            : "100% !important",
                          height: "100% !important",
                          border: "1px dotted #333",
                          borderRadius: "5px",
                          order: !isMultiSelect ? MAX_FILE_NUMBER - 10 : 10,
                        }}
                      >
                        <img
                          src={value}
                          alt="No uploaded image"
                          style={{
                            objectFit: "cover",
                          }}
                        />
                      </Box>
                    </Box>
                  )}
                  <Box
                    width="100% !important"
                    height="100%"
                    display={isMultiSelect || field.value ? "flex" : "none"}
                    flexWrap="wrap"
                    justifyContent="start"
                    gap={2}
                    sx={{
                      opacity: isMultiSelect ? "1" : "0.5",
                      overflow: isMultiSelect ? "auto" : "hidden",
                      paddingTop: isMultiSelect ? "5px" : "",
                      paddingBottom: isMultiSelect ? "5px" : "",
                    }}
                  >
                    {field.value && (
                      <>
                        {field.value.map((file, key) => (
                          <Box
                            key={key}
                            sx={{
                              position: "relative",
                              width: isMultiSelect
                                ? "178px !important"
                                : "100% !important",
                              height: "100% !important",
                              border: "1px dotted #333",
                              borderRadius: "5px",
                              order: !isMultiSelect
                                ? MAX_FILE_NUMBER - key
                                : key,
                            }}
                          >
                            <img
                              src={URL.createObjectURL(file)}
                              alt="No uploaded image"
                              style={{
                                objectFit: "cover",
                              }}
                            />
                            {isMultiSelect && (
                              <Button
                                className="primaryShadowRoundedSmall"
                                sx={{
                                  position: "absolute",
                                  top: "10px",
                                  right: "10px",
                                }}
                                onClick={() => removeImg(file.name)}
                              >
                                <DeleteOutlineIcon
                                  fontSize="small"
                                  sx={{ color: "black" }}
                                />
                              </Button>
                            )}
                          </Box>
                        ))}
                      </>
                    )}
                    {isMultiSelect && Array.isArray(items) && items.length
                      ? items.map((val, key) => (
                          <Box
                            key={key + 100}
                            sx={{
                              position: "relative",
                              width: isMultiSelect
                                ? "178px !important"
                                : "100% !important",
                              height: "100% !important",
                              border: "1px dotted #333",
                              borderRadius: "5px",
                              order: !isMultiSelect
                                ? MAX_FILE_NUMBER - key
                                : key,
                            }}
                          >
                            <img
                              src={val.image}
                              alt="No uploaded image"
                              style={{
                                objectFit: "cover",
                              }}
                            />
                            {isMultiSelect && (
                              <Button
                                className="primaryShadowRoundedSmall"
                                sx={{
                                  position: "absolute",
                                  top: "10px",
                                  right: "10px",
                                }}
                                onClick={() => removeItemsImg(key)}
                              >
                                <DeleteOutlineIcon
                                  fontSize="small"
                                  sx={{ color: "black" }}
                                />
                              </Button>
                            )}
                          </Box>
                        ))
                      : null}
                    {isMultiSelect && itemsNum ? (
                      <Box
                        key="addimage"
                        sx={{
                          position: "relative",
                          width: isMultiSelect
                            ? "178px !important"
                            : "99% !important",
                          height: "98% !important",
                          border: "1px dotted #333",
                          borderRadius: "5px",
                          order: itemsNum + 1,
                        }}
                      >
                        <Button
                          className="primaryShadowRounded"
                          sx={{
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                          }}
                          onClick={addOrEditImg}
                        >
                          <img
                            src={label === "Edit Cover" ? EditIcon : AddImage}
                            alt="logo image"
                          />
                        </Button>
                      </Box>
                    ) : null}
                  </Box>

                  <input
                    {...field}
                    ref={(el) => {
                      field.ref(el); // Bind the ref from Controller
                      inputRef.current = el; // Save the ref to your ref variable
                    }}
                    id={field.name}
                    value={""}
                    type="file"
                    multiple={isMultiSelect} // Allow multiple file selection
                    onChange={(e) => {
                      const selectedFiles = Array.from(e.target.files || []);
                      const maxSizeInBytes = 5 * 1024 * 1024; // 5MB in bytes

                      // Check for duplicates based on file name
                      const uniqueSelectedFiles = selectedFiles.filter(
                        (newFile) =>
                          !field?.value?.some(
                            (existingFile) => existingFile.name === newFile.name
                          )
                      );

                      // Filter files based on size (less than or equal to 5MB)
                      const validSelectedFiles = uniqueSelectedFiles.filter(
                        (file) => file.size <= maxSizeInBytes
                      );

                      // get the invalid files.
                      const invalidSelectedFiles = uniqueSelectedFiles.filter(
                        (file) => file.size > maxSizeInBytes
                      );

                      if (
                        validSelectedFiles.length !== uniqueSelectedFiles.length
                      ) {
                        const fileCount = invalidSelectedFiles.length;
                        const filePlural = fileCount === 1 ? "file" : "files";
                        const hasHave = fileCount === 1 ? "has" : "have";
                        const fileNames = invalidSelectedFiles
                          .map((file) => file.name)
                          .join(", ");

                        toast.error(
                          `The size of ${fileCount} ${filePlural} (${fileNames}) is too large. \nThe ${filePlural} ${hasHave} been ignored. \nPlease upload only files less than 5MB.`
                        );
                      }

                      const files = field.value || [];
                      if (isMultiSelect) {
                        field.onChange([...files, ...validSelectedFiles]);
                      } else {
                        field.onChange([...validSelectedFiles]);
                      }
                    }}
                    accept={acceptedFormats.join(",")} // Define accepted file formats
                    style={{ display: "none" }} // Hide the input element
                  />
                  {frontType === "image" ? (
                    <Button
                      className="primaryShadowRounded"
                      sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        display:
                          (isMultiSelect && field.value?.length > 0) ||
                          (Array.isArray(value) && value.length > 0)
                            ? "none !important"
                            : "flex !important",
                      }}
                      onClick={addOrEditImg}
                    >
                      <img
                        src={label === "Edit Cover" ? EditIcon : AddImage}
                        alt="logo image"
                      />
                    </Button>
                  ) : (
                    <React.Fragment>
                      <UploadIcon />
                      <Typography variant="body1" color="primary">
                        Upload your files here.
                      </Typography>
                      <Typography variant="body2" color="primary">
                        (Accepted file formats: {acceptedString.join(", ")})
                      </Typography>
                    </React.Fragment>
                  )}
                </div>
                {error && (
                  <Box>
                    <FormHelperText error>{error.message}</FormHelperText>
                  </Box>
                )}
              </>
            );
          }}
        />
      </FormControl>
    </div>
  );
};

export default CustomFileSelect;
