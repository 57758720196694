import axios from "axios";
import swal from "sweetalert";
import { logout } from "../store/actions/AuthActions";
import {
  ACCEPT_INQUIRY_LIST,
  ACTION_QNINQUERY,
  CHANGE_PASSWORD,
  CLIENTDETAIL,
  CONSULTANT_PAYMENT,
  DASHBOARD,
  INQUIRY_LIST,
  LOGIN,
  SIGNUP,
  LOGOUT,
  REQUEST,
  SUBSCRIPTION,
  USERS,
  USERS_ACTION,
  VIRTUAL_CONSULTANT,
  EXCHANGE_TOKEN,
  PINTEREST_TOKEN_VALIDATION
} from "./AuthApiEndpoints";
import instance from "./Instance";

export function signUp(email, password) {
  //axios call

  // const postData = {
  //   email,
  //   password,
  //   returnSecureToken: true,
  // };

  // return axios.post(
  //   `https://identitytoolkit.googleapis.com/v1/accounts:signUp?key=AIzaSyD3RPAp3nuETDn9OQimqn_YF6zdzqWITII`,
  //   postData
  // );
  const postData = {
    email,
    password,
  };

  return instance.post(SIGNUP, postData);
}

export function login(email, password) {
  const postData = {
    email,
    password,
  };

  return instance.post(LOGIN, postData);
}
export async function changePassword(postData) {
  const data = localStorage.getItem("taldDetails");
  const myHeaders = {
    token: `${data}`,
  };
  const response = await instance.put(CHANGE_PASSWORD, postData, {
    headers: myHeaders,
  });
  return response;
}
export function logOut() {
  const data = localStorage.getItem("taldDetails");
  const myHeaders = {
    Authorization: `Bearer ${data}`,
  };

  return instance.post(
    LOGOUT,
    { test: "" },
    {
      headers: myHeaders,
    }
  );
}

export function dashboardData(startDate, endDate) {
  const data = localStorage.getItem("taldDetails");
  const myHeaders = {
    Authorization: `Bearer ${data}`,
  };

  return instance.get(
    DASHBOARD + `?startDate=${startDate}&endDate=${endDate}`,
    {
      headers: myHeaders,
    }
  );
}
export function getAllUsers(currentPage, limit, search) {
  const data = localStorage.getItem("taldDetails");
  const myHeaders = {
    Authorization: `Bearer ${data}`,
  };

  return instance.get(
    USERS + `?page=${currentPage}&limit=${limit}&search=${search}`,
    {
      headers: myHeaders,
    }
  );
}

export function exchangeCodeForToken(token) {
  const data = localStorage.getItem("taldDetails");
  const myHeaders = {
    Authorization: `Bearer ${data}`,
  };

  return instance.post(
    EXCHANGE_TOKEN,
    { token: token },
    {
      headers: myHeaders,
    }
  );
}
export function pinterestTokenValidation(token) {
  const data = localStorage.getItem("taldDetails");
  const myHeaders = {
    Authorization: `Bearer ${data}`,
  };

  return instance.get(
    PINTEREST_TOKEN_VALIDATION,
    {
      headers: myHeaders,
    }
  );
}



export function getSubscription(currentPage, limit) {
  const data = localStorage.getItem("taldDetails");
  const myHeaders = {
    Authorization: `Bearer ${data}`,
  };

  return instance.get(SUBSCRIPTION + `?page=${currentPage}&limit=${limit}`, {
    headers: myHeaders,
  });
}

export function getInqueryList(currentPage, limit) {
  const data = localStorage.getItem("taldDetails");
  const myHeaders = {
    Authorization: `Bearer ${data}`,
  };

  return instance.get(INQUIRY_LIST + `?page=${currentPage}&limit=${limit}`, {
    headers: myHeaders,
  });
}

export function getAcceptInqueryList(currentPage, limit) {
  const data = localStorage.getItem("taldDetails");
  const myHeaders = {
    Authorization: `Bearer ${data}`,
  };

  return instance.get(
    ACCEPT_INQUIRY_LIST + `?page=${currentPage}&limit=${limit}`,
    {
      headers: myHeaders,
    }
  );
}

export function getVirtualConsultant(currentPage, limit) {
  const data = localStorage.getItem("taldDetails");
  const myHeaders = {
    Authorization: `Bearer ${data}`,
  };

  return instance.get(
    VIRTUAL_CONSULTANT + `?page=${currentPage}&limit=${limit}`,
    {
      headers: myHeaders,
    }
  );
}
export function getConsultationPayments(currentPage, limit) {
  const data = localStorage.getItem("taldDetails");
  const myHeaders = {
    Authorization: `Bearer ${data}`,
  };

  return instance.get(
    CONSULTANT_PAYMENT + `?page=${currentPage}&limit=${limit}`,
    {
      headers: myHeaders,
    }
  );
}

export function getRequest(currentPage, limit, search) {
  const data = localStorage.getItem("taldDetails");
  const myHeaders = {
    Authorization: `Bearer ${data}`,
  };

  return instance.get(
    REQUEST + `?page=${currentPage}&limit=${limit}&search=${search}`,
    {
      headers: myHeaders,
    }
  );
}
export function getClientDetails(clientId) {
  const data = localStorage.getItem("taldDetails");
  const myHeaders = {
    Authorization: `Bearer ${data}`,
  };

  return instance.get(CLIENTDETAIL + `?&userId=${clientId}`, {
    headers: myHeaders,
  });
}
export function userAction(userId) {
  const data = localStorage.getItem("taldDetails");
  const myHeaders = {
    Authorization: `Bearer ${data}`,
  };

  return instance.put(
    USERS_ACTION,
    { userId },
    {
      headers: myHeaders,
    }
  );
}

export function actionOnInquery(Id, status) {
  const data = localStorage.getItem("taldDetails");
  const myHeaders = {
    Authorization: `Bearer ${data}`,
  };

  return instance.put(
    ACTION_QNINQUERY,
    { Id, status },
    {
      headers: myHeaders,
    }
  );
}

export function formatError(errorResponse) {
  switch (errorResponse.error.message) {
    case "EMAIL_EXISTS":
      //return 'Email already exists';
      swal("Oops", "Email already exists", "error");
      break;
    case "EMAIL_NOT_FOUND":
      //return 'Email not found';
      swal("Oops", "Email not found", "error", { button: "Try Again!" });
      break;
    case "INVALID_PASSWORD":
      //return 'Invalid Password';
      swal("Oops", "Invalid Password", "error", { button: "Try Again!" });
      break;
    case "USER_DISABLED":
      return "User Disabled";

    default:
      return "";
  }
}

export function saveTokenInLocalStorage(tokenDetails) {
  //   tokenDetails.expireDate = new Date(
  //     new Date().getTime() + tokenDetails.expiresIn * 1000
  //   );
  // localStorage.setItem("userId", userId);
  localStorage.setItem("taldDetails", tokenDetails);
}

export function runLogoutTimer(dispatch, timer, history) {
  setTimeout(() => {
    dispatch(logout(history));
  }, timer);
}

export function checkAutoLogin(dispatch, history) {
  // const tokenDetailsString = localStorage.getItem("userDetails");
  // let tokenDetails = "";
  // if (!tokenDetailsString) {
  //   dispatch(logout(history));
  //   return;
  // }
  // tokenDetails = JSON.parse(tokenDetailsString);
  // let expireDate = new Date(tokenDetails.expireDate);
  // let todaysDate = new Date();
  // if (todaysDate > expireDate) {
  //   dispatch(logout(history));
  //   return;
  // }
  // dispatch(loginConfirmedAction(tokenDetails));
  // const timer = expireDate.getTime() - todaysDate.getTime();
  // runLogoutTimer(dispatch, timer, history);
}
