import React, { useState, useEffect } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import {
  createMetadescription,
  updateMetadescription,
} from "../../services/MetaDescription/MetaDescriptionService";
import { toast } from "react-toastify";

export default function AddMetaDescriptionModal({ show, onHide, table, meta }) {
  const [metaDescription, setMetaDescription] = useState("");
  const [userId, setUserId] = useState(null);

  const styles = {
    button: {
      background: "#7e7e7e",
      padding: "10px 20px",
      cursor: "pointer",
      fontSize: "14px",
    },
    buttonClose: {
      background: "#000",
      padding: "10px 20px",
      cursor: "pointer",
      fontSize: "14px",
    },
  };

  useEffect(() => {
    if (meta) {
      setMetaDescription(meta.metaDescription || "");
      setUserId(meta._id || null);
    }
  }, [meta]);

  function handleSave() {
    if (userId) {
      updateMetadescription(metaDescription, userId)
        .then(() => {
          toast.success("Metadescription updated successfully");
          onHide();
          table();
        })
        .catch((err) => {
          toast.error("Failed to update metadescription");
        });
    } else {
      createMetadescription(metaDescription, userId)
        .then(() => {
          toast.success("Metadescription created successfully");
          onHide();
          table();
        })
        .catch((err) => {
          toast.error("Failed to create metadescription");
        });
    }
  }

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>
          {userId ? "Edit Metadescription" : "Add Metadescription"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group>
          <Form.Label>Metadescription</Form.Label>
          <Form.Control
            as="textarea" 
            value={metaDescription}
            onChange={(e) => setMetaDescription(e.target.value)}
            placeholder="Enter Metadescription"
            rows={4} 
          />
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button style={styles.buttonClose} onClick={onHide}>
          Close
        </Button>
        <Button style={styles.button} onClick={handleSave}>
          {userId ? "Update" : "Create"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
