import React from "react";
import CustomTextField from "./CustomTextField";
import CustomRangeSlider from "./CustomRangeSlider";
import CustomCheckboxGroup from "./CustomCheckboxGroup";
import CustomFileSelect from "./CustomFileSelect";
import CustomLabel from "./CustomLabel";
import CustomTextArea from "./CustomTextArea";
import CustomDropdown from "./CustomDropdown";
import CustomSelect from "./CustomSelect";
import CustomRadioGroup from "./CustomRadioGroup";
import GoogleAutoComplete from "./GoogleAutoComplete";
import ImageField from "./ImageField";

export function loadGoogleMaps() {
  if (typeof window === "undefined") {
    // Don't load Google Maps on the server-side
    return Promise.resolve();
  }

  if (!window.google || !window.google.maps) {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyBb-euwpA16Yp0pHtIlCUaMyoUVcoRtkFk
      &libraries=places`;
      script.onload = resolve;
      document.head.appendChild(script);
    });
  }
  return Promise.resolve();
}

const InputFieldProvider = (props) => {
  const { type } = props;
  switch (type) {
    case "text":
      return <CustomTextField {...props} disabled={true} />;
    case "textarea":
      return <CustomTextArea {...props} disabled={true} />;
    case "dropdown":
      return <CustomDropdown {...props} disabled={true} />;
    case "select":
      return <CustomSelect {...props} disabled={true} />;
    case "multiselect":
      return <CustomCheckboxGroup {...props} disabled={true} />;
    case "radio":
      return <CustomRadioGroup {...props} disabled={true} />;
    case "file":
      return <CustomFileSelect {...props} disabled={true} />;
    case "range":
      return <CustomRangeSlider {...props} disabled={true} />;
    case "googleAutocomplete":
      return <GoogleAutoComplete {...props} disabled={true} />;
    case "label":
      return <CustomLabel {...props} />;
    case "image":
      return <ImageField {...props} />;
    default:
      return null;
  }
};

export default InputFieldProvider;
