import React, { useState } from "react";
import { Badge } from "react-bootstrap";
import { Collapse } from "@mui/material";
import moment from "moment";

function CollapseTableRow({ item }) {
  const [expanded, setExpanded] = useState(false);
  console.log(item, "item");
  return (
    <>
      <tr
        onClick={() => setExpanded((exp) => !exp)}
        style={{ cursor: "pointer" }}
      >
        <td>{moment(item.createdAt).format("MM/DD/YYYY")}</td>
        <td style={{ textTransform: "capitalize" }}>
          {item?.designer?.name} {item?.designer?.lastName}
        </td>
        <td>{item?.designer?.email}</td>
        <td>
          {(() => {
            switch (item.status) {
              case "Rejected":
                return <Badge variant="danger light">Rejected</Badge>;
              case "Pending":
                return <Badge variant="warning light">Pending</Badge>;
              case "Accepted":
                return <Badge variant="success light">Accepted</Badge>;
              default:
                return null;
            }
          })()}
        </td>
      </tr>

      <tr>
        <td colSpan="4" style={{ padding: 0 }}>
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <div
              style={{
                width: "100%",
                backgroundColor: "#f9f9f9",
                padding: "20px",
                border: "1px solid #ddd",
                borderRadius: "4px",
                margin: "10px 0",
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                boxSizing: "border-box",
              }}
            >
              <div
                style={{
                  fontWeight: "bold",
                  fontSize: "1.1em",
                  textDecoration: "underline",
                }}
              >
                Project Details
              </div>
              <div>
                <strong>First Name:</strong> {item?.projectId?.firstName}
              </div>
              <div>
                <strong>Last Name:</strong> {item?.projectId?.lastName}
              </div>
              <div>
                <strong>Email:</strong> {item?.projectId?.email}
              </div>
              <div>
                <strong>Project Location:</strong> {item?.projectId?.address}
              </div>
              <div>
                <strong>Project Summary:</strong>{" "}
                <div style={{ whiteSpace: "pre-line" }}>
                  {item?.projectId?.projectSummary}
                </div>
              </div>
              <div>
                <strong>Allocated Project Funds:</strong>{" "}
                {item?.projectId?.projectFund
                  ? "$" + item.projectId.projectFund
                  : "None"}
              </div>
            </div>
          </Collapse>
        </td>
      </tr>
    </>
  );
}

export default CollapseTableRow;
