import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Dropdown, Tab, Nav } from "react-bootstrap";
import CountUp from "react-countup";
import { RangeDatePicker } from "@y0c/react-datepicker";
import { dashboardData } from "../../../services/AuthService";
const Home = (props) => {
  const [loader, setLoader] = useState(false);
  const [usersData, setUsersData] = useState([]);
  const [selectedStartDate, setSelectedStartDate] = useState("");
  const [selectedEndDate, setSelectedEndDate] = useState("");
  console.log(
    selectedStartDate,
    "selectedStartDate",
    selectedEndDate,
    "selectedEndDate"
  );
  const getStartAndEndOfWeek = () => {
    const currentDate = new Date();
    const dayOfWeek = currentDate.getDay(); // 0 = Sunday, 1 = Monday, ..., 6 = Saturday
    const diff = currentDate.getDate() - dayOfWeek + (dayOfWeek === 0 ? -6 : 1); // adjust when day is Sunday
    const startOfWeek = new Date(currentDate.setDate(diff));
    const endOfWeek = new Date(currentDate.setDate(diff + 6));

    // Format the dates as needed (e.g., toISOString(), toLocaleDateString(), etc.)
    const formattedStartDate = startOfWeek.toISOString().split("T")[0];
    const formattedEndDate = endOfWeek.toISOString().split("T")[0];

    setSelectedStartDate(formattedStartDate);
    setSelectedEndDate(formattedEndDate);
  };

  const getStartAndEndOfMonth = () => {
    const currentDate = new Date();
    const firstDayOfMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      1
    );
    const lastDayOfMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() + 1,
      0
    );

    // Format the dates as needed (e.g., toISOString(), toLocaleDateString(), etc.)
    const formattedStartDate = firstDayOfMonth.toISOString().split("T")[0];
    const formattedEndDate = lastDayOfMonth.toISOString().split("T")[0];

    setSelectedStartDate(formattedStartDate);
    setSelectedEndDate(formattedEndDate);
  };

  function getDashboardData() {
    setLoader(true);
    dashboardData(selectedStartDate, selectedEndDate)
      .then((response) => {
        setUsersData(response.data.data);
        setLoader(false);
        console.log(response, " table data ");
      })
      .catch((error) => {
        setLoader(false);
        console.log(error.response, "helooooooooo");
        if (error.response?.data?.statusCode === 401) {
          localStorage.clear("taldDetails");
          props.history.push("/login");
        }
      });
  }
  useEffect(() => {
    getDashboardData();
  }, []);
  useEffect(() => {
    getDashboardData();
  }, [selectedStartDate, selectedEndDate]);
  return (
    <>
      <div className="form-head d-flex mb-3 align-items-start">
        <div className="mr-auto d-none d-lg-block">
          <h2 className="text-black font-w600 mb-0">Dashboard</h2>
          <p className="mb-0">Welcome to Tald Admin!</p>
        </div>
        <div className="d-flex align-items-end gap-1">
          <div className="form-group">
            <label>Start Date</label>
            <input
              type="date"
              className="w-100  form-control"
              placeholder="Date"
              value={selectedStartDate}
              onChange={(e) => setSelectedStartDate(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label>End Date</label>
            <input
              type="date"
              className="w-100  form-control"
              placeholder="Date"
              value={selectedEndDate}
              onChange={(e) => setSelectedEndDate(e.target.value)}
            />
          </div>
          <Dropdown className="dropdown custom-dropdown">
            <Dropdown.Toggle
              className="btn btn-sm btn-secondary light d-flex align-items-center svg-btn"
              data-toggle="dropdown"
            >
              <svg
                width={28}
                height={28}
                viewBox="0 0 28 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g>
                  <path
                    d="M22.4281 2.856H21.8681V1.428C21.8681 0.56 21.2801 0 20.4401 0C19.6001 0 19.0121 0.56 19.0121 1.428V2.856H9.71606V1.428C9.71606 0.56 9.15606 0 8.28806 0C7.42006 0 6.86006 0.56 6.86006 1.428V2.856H5.57206C2.85606 2.856 0.560059 5.152 0.560059 7.868V23.016C0.560059 25.732 2.85606 28.028 5.57206 28.028H22.4281C25.1441 28.028 27.4401 25.732 27.4401 23.016V7.868C27.4401 5.152 25.1441 2.856 22.4281 2.856ZM5.57206 5.712H22.4281C23.5761 5.712 24.5841 6.72 24.5841 7.868V9.856H3.41606V7.868C3.41606 6.72 4.42406 5.712 5.57206 5.712ZM22.4281 25.144H5.57206C4.42406 25.144 3.41606 24.136 3.41606 22.988V12.712H24.5561V22.988C24.5841 24.136 23.5761 25.144 22.4281 25.144Z"
                    fill="#2F4CDD"
                  />
                </g>
              </svg>
              <div className="text-left ml-3">
                <span className="d-block fs-16">Filter Periode</span>
                {/* <small className="d-block fs-13">4 June 2020 - 4 July 2020</small> */}
              </div>
              {/* {/* <i className="fa fa-angle-down scale5 ml-3" />  */}
            </Dropdown.Toggle>
            <Dropdown.Menu className="dropdown-menu dropdown-menu-right">
              <Dropdown.Item
                className="dropdown-item"
                to="#"
                onClick={() => getStartAndEndOfWeek()}
              >
                This week
              </Dropdown.Item>
              <Dropdown.Item
                className="dropdown-item"
                to="#"
                onClick={() => getStartAndEndOfMonth()}
              >
                This month
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
      <div className="row">
        <div className="col-xl-3 col-xxl-4 col-lg-6 col-sm-6">
          <div className="widget-stat card bg-theme">
            <div className="card-body  p-4">
              <div className="media">
                <span className="mr-3">
                  <i className="flaticon-381-calendar-1"></i>
                </span>
                <div className="media-body text-black text-right">
                  <p className="mb-1"> Virtual consultation </p>
                  <small>(Booked)</small>

                  <h3 className="text-black">
                    {usersData?.virtualConsultation}
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-xxl-4 col-lg-6 col-sm-6">
          <div className="widget-stat card bg-theme">
            <div className="card-body p-4">
              <div className="media">
                <span className="mr-3">
                  <i className="flaticon-381-diamond"></i>
                </span>
                <div className="media-body text-black text-right">
                  <p className="mb-1"> Project Inquiry Placed</p>
                  <h3 className="text-black">{usersData?.ProjectInquerys}</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-xxl-4 col-lg-6 col-sm-6">
          <div className="widget-stat card bg-theme">
            <div className="card-body p-4">
              <div className="media">
                <span className="mr-3">
                  <i className="flaticon-381-heart"></i>
                </span>
                <div className="media-body text-black text-right">
                  <p className="mb-1"> Interior Designers</p>
                  <small>(Registered)</small>
                  <h3 className="text-black">{usersData?.interiorDesigner}</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-xxl-4 col-lg-6 col-sm-6">
          <div className="widget-stat card bg-theme">
            <div className="card-body p-4">
              <div className="media">
                <span className="mr-3">
                  <i className="flaticon-381-user-7"></i>
                </span>
                <div className="media-body text-black text-right">
                  <p className="mb-1"> Clients</p>
                  <small>(Registered)</small>
                  <h3 className="text-black">{usersData?.totalClient}</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-xxl-4 col-lg-6 col-sm-6">
          <div className="widget-stat card bg-theme">
            <div className="card-body p-4">
              <div className="media">
                <span className="mr-3">
                  <i className="flaticon-381-user-7"></i>
                </span>
                <div className="media-body text-black text-right">
                  <p className="mb-1">Membership revenue</p>
                  {/* <small>(Registered)</small> */}
                  <h3 className="text-black">
                    ${usersData?.membershipRevenue}
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-xxl-4 col-lg-6 col-sm-6">
          <div className="widget-stat card bg-theme">
            <div className="card-body p-4">
              <div className="media">
                <span className="mr-3">
                  <i className="flaticon-381-user-7"></i>
                </span>
                <div className="media-body text-black text-right">
                  <p className="mb-1">Consultation revenue</p>
                  {/* <small>(Registered)</small> */}
                  <h3 className="text-black">
                    ${usersData?.consultationRevenue}
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
