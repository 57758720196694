import React from "react";
import { Box } from "@mui/material";
import { PastConsultationCard } from "./PastConsultationCard";
import NoResultFound from "../NoResultFound";

const PastConsultations = ({ pastConsultations, personalMeetingUrl }) => {
  return (
    <Box display="flex" flexDirection="column" gap={4} sx={{
        width: '100%',
    }}>
      {pastConsultations?.value && pastConsultations?.value?.length > 0 ? (
        pastConsultations?.value?.map((past) => (
          <PastConsultationCard
            key={past._id}
            consultation={past}
            // personalMeetingUrl={personalMeetingUrl}
          />
        ))
      ) : (
        <NoResultFound message="No past consultations" />
      )}
    </Box>
  );
};

export default PastConsultations;
