import React, { useRef, useState } from "react";
import { Card, Nav, Tab, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import SlideDragable from "../components/PluginsMenu/Noui Slider/SlideDragable";
import img1 from "../../images/Group-626059.svg";
import img2 from "../../images/Group-626060.svg";
import img3 from "../../images/Group-626061.svg";
import img4 from "../../images/Group-626062.svg";
import uploadSvg from "../../images/cloud-computing (1) 1.svg";
export default function ProjectDetails() {
  const [type, setType] = useState("");
  const [image, setImage] = useState("");
  const focusPoint = useRef(null);
  const onClickHandler = () => {
    focusPoint.current.click();
  };
  return (
    <div>
      <div className="page-titles">
        <h4>Project Details</h4>
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="client-profile">Clients Profile</Link>
          </li>
          <li className="breadcrumb-item active">
            <Link>Project Details</Link>
          </li>
        </ol>
      </div>
      <Card>
        <Card.Header>
          <h3>Projects</h3>
        </Card.Header>
        <Card.Body>
          <div className="custom-tab-1">
            <Tab.Container defaultActiveKey="home">
              <Nav as="ul" className="nav-tabs">
                <Nav.Item as="li">
                  <Nav.Link eventKey="home" onClick={() => setType("all")}>
                    Project Details
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item as="li">
                  <Nav.Link eventKey="second" onClick={() => setType("active")}>
                    Inquiry Status
                  </Nav.Link>
                </Nav.Item>
              </Nav>
              <Tab.Content className="pt-4">
                <Tab.Pane eventKey="home">
                  <div className="bg-white rounded p-4 project-detail-card">
                    <h2 className="details-font">Living Room</h2>
                    <p>Are you the primary decision maker?</p>
                    <div
                      className="d-flex align-items-center mb-3"
                      style={{ gap: "1rem" }}
                    >
                      <input
                        type="radio"
                        id="yes"
                        class="radio-input"
                        name="decision maker"
                      />
                      <lable for="yes">Yes</lable>
                      <input
                        type="radio"
                        id="no"
                        class="radio-input"
                        name="decision maker"
                      />
                      <lable for="no">No</lable>
                    </div>
                    <p>
                      If not, please list all individuals who will be
                      responsible for approvals and payment.
                    </p>
                    <div className="mb-3">
                      <input type="text" className="w-100 border-input" />
                    </div>
                    <p>Have you worked with an interior designer before?</p>
                    <div
                      className="d-flex align-items-center mb-3"
                      style={{ gap: "1rem" }}
                    >
                      <input
                        type="radio"
                        id="yes"
                        class="radio-input"
                        name="interior designer"
                      />
                      <lable for="yes">Yes</lable>
                      <input
                        type="radio"
                        id="no"
                        class="radio-input"
                        name="interior designer"
                      />
                      <lable for="no">No</lable>
                    </div>
                    <p>What kind of space are you inquiring about?</p>
                    <div
                      className="d-flex align-items-center mb-3"
                      style={{ gap: "1rem" }}
                    >
                      <input
                        type="radio"
                        id="Residential"
                        class="radio-input"
                        name="inquiring"
                      />
                      <lable for="Residential">Residential</lable>
                      <input
                        type="radio"
                        id="Commercial"
                        class="radio-input"
                        name="inquiring"
                      />
                      <lable for="Commercial">Commercial</lable>
                    </div>

                    <p>What kind of assistance are you looking for?</p>
                    <div
                      className="d-flex align-items-center mb-3"
                      style={{ gap: "1rem" }}
                    >
                      <input
                        type="radio"
                        id="Styling/ Decorating"
                        class="radio-input"
                        name="looking"
                      />
                      <lable for="Styling/ Decorating">
                        Styling/ Decorating
                      </lable>
                      <input
                        type="radio"
                        id="Renovation"
                        class="radio-input"
                        name="looking"
                      />
                      <lable for="Commercial">Renovation</lable>
                      <input
                        type="radio"
                        className="radio-input"
                        id="New Construction"
                        name="looking"
                      />
                      <lable for="New Construction">New Construction</lable>
                    </div>

                    <p>What is your ideal start date?</p>
                    <div className="mb-3">
                      <input
                        type="date"
                        className="w-100  border-input"
                        placeholder="Date"
                      />
                    </div>
                    <div className="d-flex justify-content-end align-items-center mb-4">
                      <input type="radio" className="radio-input" />
                      <lable className="">I am flexible with dates</lable>
                    </div>
                    <p>What is your ideal Completion date?</p>
                    <div className="mb-3">
                      <input
                        type="date"
                        className="w-100 border-input"
                        placeholder="Date"
                      />
                    </div>
                    <div className="d-flex justify-content-end align-items-center">
                      <input type="radio" className="radio-input" />
                      <lable className="">I am flexible with dates</lable>
                    </div>
                    <p>
                      What have you allocated as total funds for your project
                      including services and goods?
                    </p>

                    <div className="slider-behaviour mt-4">
                      <div id="behaviour">
                        <SlideDragable />
                      </div>
                    </div>
                    <p className="pb-2">
                      Please provide a quick summary of your project.
                    </p>
                    <textarea type="text" className="w-100 mb-3" />
                    <p className="pb-2">
                      How involved would you like to be in the project?
                    </p>
                    <div
                      className="d-flex flex-column mb-3"
                      style={{ gap: "1rem" }}
                    >
                      <div className="d-flex align-items-center">
                        <input
                          type="radio"
                          className="radio-input"
                          id="option1"
                          name="project"
                        />
                        <lable className="pl-3" for="option1">
                          I want a designer I can align with on the vision and
                          work within a budget to exceed my expectations so I
                          can be as hands off as possible
                        </lable>
                      </div>

                      <div className="d-flex align-items-center">
                        <input
                          type="radio"
                          className="radio-input"
                          id="option2"
                          name="project"
                        />
                        <lable className="pl-3" for="option2">
                          I want to be a hands on part of the process and play a
                          role in making every decision.
                        </lable>
                      </div>

                      <div className="d-flex align-items-center">
                        <input
                          type="radio"
                          className="radio-input"
                          id="option3"
                          name="project"
                        />
                        <lable className="pl-3" for="option3">
                          I am somewhere in between.
                        </lable>
                      </div>
                    </div>
                    <p>Attachments</p>
                    <div className="d-flex mt-4" style={{ gap: "1rem" }}>
                      <>
                        <img src={img1} width={185} />
                        <img src={img2} width={185} />
                        <img src={img3} width={185} />
                        <img src={image ? image : img4} width={185} />
                        <div
                          className="upload-container d-flex flex-column justify-content-center align-items-center position "
                          onClick={onClickHandler}
                          style={{ cursor: "pointer" }}
                        >
                          <img src={uploadSvg} />
                          <p>Upload</p>
                        </div>
                        <input
                          type="file"
                          ref={focusPoint}
                          style={{ display: "none" }}
                          onChange={(e) =>
                            setImage(URL.createObjectURL(e.target.files[0]))
                          }
                        />
                      </>
                    </div>
                    <button className="btn btn-outline-secondary w-100 mt-5">
                      Delete
                    </button>
                  </div>
                </Tab.Pane>
              </Tab.Content>
              <Tab.Content>
                <Tab.Pane eventKey="second">
                  <Card className="bg-white">
                    <Card.Body>
                      <Table responsive>
                        <thead style={{ color: "black" }}>
                          <tr>
                            <th>
                              <strong>Service Provider</strong>
                            </th>
                            <th>
                              <strong>Inquiry Sent</strong>
                            </th>
                            <th>
                              <strong>Status</strong>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Luma Interiors </td>
                            <td>3/11/2023, 9:23PM</td>
                            <td>Awaiting Response</td>
                          </tr>
                          <tr>
                            <td>Habachy Designs </td>
                            <td>3/11/2023, 9:23PM</td>
                            <td>Awaiting Response</td>
                          </tr>
                          <tr>
                            <td>Vanessa Rome </td>
                            <td>3/11/2023, 9:23PM</td>
                            <td>Awaiting Response</td>
                          </tr>
                          <tr>
                            <td>Luma Interiors </td>
                            <td>3/11/2023, 9:23PM</td>
                            <td>Awaiting Response</td>
                          </tr>
                          <tr>
                            <td>Habachy Designs </td>
                            <td>3/11/2023, 9:23PM</td>
                            <td>Awaiting Response</td>
                          </tr>
                          <tr>
                            <td>Vanessa Rome </td>
                            <td>3/11/2023, 9:23PM</td>
                            <td>Awaiting Response</td>
                          </tr>
                        </tbody>
                      </Table>
                    </Card.Body>
                  </Card>
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </div>
        </Card.Body>
      </Card>
    </div>
  );
}
