"use client";
import React, { useEffect, useMemo, useState } from "react";
import { getConsultationDetails } from "../../services/InteriorDesigner/InteriorDesignerService";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { Box, Grid, Stack, Typography } from "@mui/material";
import Loading from "../components/common/Loading";
// import { Box, LinearProgress, Grid, Typography, Stack } from '@mui/material';
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import ConfirmedConsultations from "../components/consultant/ConfirmedConsultations";
import PastConsultations from "../components/consultant/PastConsultations";
import Pagination from "../components/common/Pagination";
import './Consultant.css'

export default function Consultation() {
    const { id } = useParams();
    const [value, setValue] = React.useState("1");
    const [loading, setLoading] = React.useState(false);
    const [consultations, setConsultations] = React.useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [pageCount, setPageCount] = useState(0);
    const [listLength, setListLength] = useState("");

    const limit = 10;
    const handleChange = (event, newValue) => {
        setValue(newValue);
        setCurrentPage(0);
    };

    const fetch = async () => {
        try {
            setLoading(true);
            const response = await getConsultationDetails({ page: currentPage, id, limit: limit });
            setConsultations(response?.data?.data);
            let total = 0;
            if (value === "1") {
                total = response?.data?.data?.find((consul) => consul.type === "confirmedConsultations")?.totalCount ?? 0;
            } else if (value === "2") {
                total = response?.data?.data?.find((consul) => consul.type === "pastConsultations")?.totalCount ?? 0;
            }
            setPageCount(Math.ceil(total / limit));
            setListLength(total);
        } catch (error) {
            console.log(error, "while fetching consultation detail");
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetch();
    }, [currentPage]);

    const confirmedConsultations = useMemo(
        () => consultations.find((consul) => consul.type === "confirmedConsultations"),
        [consultations]
    );
    const pastConsultations = useMemo(
        () => consultations.find((consul) => consul.type === "pastConsultations"),
        [consultations]
    );

    return (
        <Stack spacing={2} p={1}>
            <Typography variant="h3" sx={{ fontSize: '28px' }}>Consultations</Typography>
            {loading ? (
                <Loading isSkeleton />
            ) : (
                <Box className="consultant" sx={{ width: "100%" }}>
                    <TabContext value={value}>
                        <Box sx={{ border: "none" }}>
                            <TabList onChange={handleChange} aria-label="lab API tabs example">
                                <Tab
                                    label="Confirmed Consultations"
                                    value="1"
                                    sx={{
                                        fontSize: "16px!important",
                                        px: "0px!important",
                                        textTransform: "none",
                                        py: '5px !important',
                                        mr: 2,
                                        "&.MuiTabs-indicator": {
                                            height: "1px!important",
                                        },
                                        color: '#333 !important',
                                        opacity: value == '1' ? '1' : '0.5'
                                    }}
                                />
                                <Tab
                                    label="Past Consultations"
                                    value="2"
                                    sx={{
                                        fontSize: "16px!important",
                                        textTransform: "none",
                                        px: "0px!important",
                                        mr: 2,
                                        py: '5px !important',
                                        "&.MuiTabs-indicator": {
                                            height: "1px !important",
                                        },
                                        color: '#333 !important',
                                        opacity: value == '2' ? '1' : '0.5'
                                    }}
                                />
                            </TabList>
                        </Box>
                        <Box sx={{
                            mx: '-10px'
                        }}>
                            <TabPanel
                                className="no-x-padding"
                                value="1"
                                sx={{
                                    // height: "calc(100vh - 250px)",
                                    overflow: "auto",
                                }}
                            >
                                <Grid container spacing={2}>
                                    <ConfirmedConsultations
                                        confirmedConsultations={confirmedConsultations}
                                        refresh={fetch}
                                    //   personalMeetingUrl={personalMeetingUrl}
                                    />
                                </Grid>
                            </TabPanel>
                        </Box>
                        <TabPanel
                            value="2"
                            sx={{
                                // height: "calc(100vh - 250px)",
                                overflow: "auto",
                            }}
                        >
                            <Grid container spacing={2}>
                                <PastConsultations pastConsultations={pastConsultations} />{" "}
                            </Grid>
                        </TabPanel>
                    </TabContext>
                </Box>
            )}
            {consultations?.length === 0 ? (
                ""
            ) : (
                <div className="">
                    <div className="d-flex justify-content-between align-items-center">
                        <div className="dataTables_info">
                            Showing {currentPage * limit + 1} to{" "}
                            {listLength > (currentPage + 1) * limit ? (currentPage + 1) * limit : listLength} of {listLength} entries
                        </div>
                        <Pagination pageCount={pageCount} pageValue={currentPage} setPage={setCurrentPage} />
                    </div>
                </div>
            )}
        </Stack>
    );
}
