import { Stack, Skeleton, CircularProgress } from "@mui/material";
const Loading = ({ isSkeleton }) => {
  if (!isSkeleton) {
    return (
      <div className="circular-loading">
        <CircularProgress />
      </div>
    );
  }
  return (
    <Stack spacing={2} sx={{ maxWidth: "800px" }}>
      <Stack spacing={1}>
        <Skeleton animation="wave" variant="text" sx={{ fontSize: "1rem" }} />
        <Skeleton animation="wave" variant="rounded" height={20} />
      </Stack>
      <Stack spacing={1}>
        <Skeleton animation="wave" variant="text" sx={{ fontSize: "1rem" }} />
        <Skeleton animation="wave" variant="rounded" height={20} />
      </Stack>
      <Stack spacing={1}>
        <Skeleton animation="wave" variant="text" sx={{ fontSize: "1rem" }} />
        <Skeleton animation="wave" variant="rounded" height={20} />
      </Stack>
    </Stack>
  );
};

export default Loading;
