import React from "react";
import { Box } from "@mui/material";
import EditProject from "./EditProject";

// Component for projects
const PortfolioProjects = ({ projects }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexWrap: "wrap",
        gap: 2,
        overflow: "auto",
        height: "calc(100vh - 195px)",
      }}
    >
      {projects.map((project, key) => (
        <React.Fragment key={key}>
          <EditProject project={project} />
        </React.Fragment>
      ))}
    </Box>
  );
};

export default PortfolioProjects;
