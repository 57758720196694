import React from "react";
import {
  FormControl,
  InputLabel,
  TextField,
  Typography,
  Box,
  IconButton,
} from "@mui/material";
import { useFormContext, Controller } from "react-hook-form";
import { getLettersBeforeDollar } from "../../../lib/common";
import InputAdornment from "@mui/material/InputAdornment";
import { HiEye, HiEyeOff } from "react-icons/hi";

const CustomLabel = ({
  label,
  label_edit,
  width = "203px",
  dependent,
  dependent_edit,
  dependentValue,
  subLabel = "",
  fontSize = "14px",
  nestedFields = [],
  varient = "standard",
  showLabel = true,
  disabled = false,
  isPassword = false,
  varientType,
  className,
  name,
}) => {
  const contextValues = useFormContext();
  const { control, getValues, setValue, watch } = contextValues;

  const [showPassword, setShowPassword] = React.useState();

  const handleClickShowPassword = (name) =>
    setShowPassword((show) => ({
      ...(show ?? {}),
      [`${name}`]: show?.[`${name}`] ? !show?.[`${name}`] : true,
    }));

  const editDisable = name === "Range" ? true : disabled;
  const dependentKey = editDisable ? dependent : dependent_edit;
  const watchedDependency = dependentKey ? watch(dependentKey) : null;
  if (watchedDependency && watchedDependency !== dependentValue) return <></>;
  return (
    <FormControl fullWidth className={`${varient} ${className}`}>
      {showLabel && (
        <InputLabel sx={{ marginBottom: "8px !important" }}>
          <span
            style={{
              fontSize: fontSize,
              letterSpacing: "0px",
              textTransform: "none",
            }}
          >
            {editDisable ? label : label_edit ?? label}
          </span>
        </InputLabel>
      )}
      {subLabel && (
        <InputLabel
          sx={{ marginBottom: "16px !important", whiteSpace: "wrap" }}
        >
          <span
            style={{
              color: "#9E9E9E",
              textTransform: "none",
              letterSpacing: "0px",
              fontSize: fontSize,
            }}
          >
            {subLabel}
          </span>
        </InputLabel>
      )}
      <Box
        sx={{
          display: "block",
          width: width,
          marginTop: "12px",
        }}
      >
        {nestedFields.length > 0 &&
          nestedFields.map((item, index) => {
            const staticPlaceholder = getLettersBeforeDollar(item.prefix || "");

            return (
              <Controller
                key={item.name}
                name={
                  !editDisable
                    ? item.edit_name ?? `${item.name}_edit`
                    : item.name
                }
                control={control}
                defaultValue={item.defaultValue}
                render={({ field, fieldState: { error, invalid } }) => (
                  <TextField
                    {...field}
                    type={
                      isPassword && !showPassword?.[field.name]
                        ? "password"
                        : "text"
                    }
                    disabled={editDisable}
                    helperText={error?.message}
                    error={invalid}
                    variant={varient}
                    onChange={(e) => {
                      const inputString = e.target.value;
                      const numbersOnly = inputString.replace(/\D/g, "");
                      const value =
                        varientType === "number" ? numbersOnly : inputString;
                      setValue(field.name, value);
                    }}
                    InputProps={{
                      startAdornment: (
                        <>
                          {item.prefix && (
                            <InputAdornment position="start">
                              <Typography
                                sx={{
                                  color: "#333",
                                  marginRight: "-5px",
                                  fontSize: `14px !important`,
                                }}
                              >
                                <span
                                  style={{
                                    marginRight: staticPlaceholder
                                      ? "20px"
                                      : "0px",
                                  }}
                                >
                                  {staticPlaceholder}
                                </span>
                                <span>$</span>
                              </Typography>
                            </InputAdornment>
                          )}
                        </>
                      ),
                      endAdornment:
                        item.varientType === "password" ? (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={() =>
                                handleClickShowPassword(field.name)
                              }
                            >
                              {showPassword?.[field.name] ? (
                                <HiEyeOff />
                              ) : (
                                <HiEye />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ) : (
                          <></>
                        ),
                    }}
                    inputProps={{
                      shrink: "true",
                      placeholder: item.placeholder,
                      style: {
                        fontSize: "14px!important",
                      },
                    }}
                    sx={{
                      fontSize: "14px!important",
                      "&::placeholder": {
                        fontSize: `14px !important`,
                      },
                      "& .MuiFormLabel-root": {
                        fontSize: "14px!important",
                      },
                      width: "100%",
                      marginBottom:
                        index !== nestedFields.length - 1 ? "32px" : "-5px",
                    }}
                  />
                )}
              />
            );
          })}
      </Box>
    </FormControl>
  );
};

export default CustomLabel;
