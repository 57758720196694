import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import { toast, ToastContainer } from 'react-toastify'
import Spinner from '../common/Spinner'
import { userAction } from "../../../services/InteriorDesigner/InteriorDesignerService";

export default function ConfirmModal({ userId, show, onHide }) {
    const [loader, setLoader] = useState(false);
   
    const onSubmit = async (e) => {
      e.preventDefault();
      setLoader(true);
      onAction(userId, 'isApproved')
    }

    function onAction(userId, key) {
      userAction(userId, key)
        .then((response) => {
          notifyTopRight("Approved Successfully!");
          setLoader(false);
          onHide();
        })
        .catch((error) => {
          setLoader(false);
          notifyError(error.response?.data?.message);
          if (error.response?.data?.statusCode === 401) {
            localStorage.removeItem("taldDetails");
          }
        });
    }

    const notifyTopRight = () => {
      toast.success(`✅ Successfully Approved!`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    };
    const notifyError = (error) => {
      toast.error(`❌${error}`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    };

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Modal className="modal fade" show={show} onHide={onHide} centered>
        <div className="" role="document">
          <div className="">
            <form onSubmit={(e)=>onSubmit(e)}>
              <div className="modal-header">
                <h4 className="modal-title fs-20">Confirm</h4>
              </div>
              <div className="modal-body">
                <i
                  className="flaticon-cancel-12 close"
                  data-dismiss="modal"
                ></i>
                <div className="add-contact-box">
                  <div className="add-contact-content">
                    Are you sure you want to approve this interior designer?
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="submit"
                  className="btn btn-primary"
                >
                  Yes
                </button>
                <button
                  type="button"
                  onClick={() => onHide()}
                  className="btn btn-danger"
                >
                  {" "}
                  <i className="flaticon-delete-1"></i> No
                </button>
              </div>
            </form>
          </div>
        </div>
        {loader && <Spinner />}
      </Modal>
    </>
  )
}
