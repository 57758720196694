export const SIGNUP = "admin/signup";

export const LOGIN = "admin/login";



export const CHANGE_PASSWORD = "admin/changePassword";

export const LOGOUT = "admin/logout";

export const USERS = "admin/users";

export const EXCHANGE_TOKEN = 'admin/pinterestTokenExchange'

export const PINTEREST_TOKEN_VALIDATION = 'admin/pinterestTokenValidation'

export const USERS_ACTION = "admin/userAction";

export const DASHBOARD = "admin/dashboard";

export const CLIENTDETAIL = "admin/getClientDetails";

export const INQUIRY_LIST = "admin/inqueryList";

export const ACTION_QNINQUERY = "admin/actionOnInquery";

export const ACCEPT_INQUIRY_LIST = "admin/approvedInqueryList";

export const VIRTUAL_CONSULTANT = "admin/virtualConsultations";

export const SUBSCRIPTION = "admin/getSubscriptions";

export const REQUEST = "admin/requests";

export const CONSULTANT_PAYMENT = "admin/getConsultationPayments";
