import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";

const ProfilesSaved = ({ interiorProfile }) => {
  const saveProfiles = interiorProfile.saveProfiles;
  console.log(saveProfiles, "saveProfiles");

  return (
    <Box width="100%">
      <Typography
        variant="h3"
        style={{ fontSize: "1.5rem", width: "100%", textAlign: "left" }}
      >
        Profiles Saved
      </Typography>
      {saveProfiles.length > 0 ? (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <strong>NAME</strong>
              </TableCell>
              <TableCell>
                <strong>EMAIL</strong>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {saveProfiles.map((profile, idx) => (
              console.log(profile, "profile"),
              <TableRow key={idx}>
                <TableCell>{profile.name}</TableCell>
                <TableCell>{profile.email}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      ) : (
        <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          sx={{ height: "calc(80vh - 300px)" }}
        >
          <Typography variant="h5">No profiles saved yet.</Typography>
        </Box>
      )}
    </Box>
  );
};

export default ProfilesSaved;
