import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import Spinner from "../common/Spinner";
import { createInterior } from "../../../services/InteriorDesigner/InteriorDesignerService";
import { throwError } from "@syncfusion/ej2-base";

export default function AddInteriorD({ show, onHide, table }) {
  const [loader, setLoader] = useState(false);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  // const [slug, setSlug] = useState("");

  let errorsObj = {
    name: "",
    email: "",
    // slug: "",
  };
  const [errors, setErrors] = useState(errorsObj);

  const onSubmit = async () => {
    setLoader(true);
    const data = {
      name,
      email,
      // slug,
    };
    try {
      const response = await createInterior(data);
      console.log("[response.data]", response.data);
      if (response && response.data) {
        if (response.data.statusCode === 200) {
          notifyTopRight(response.data.message);
          setLoader(false);
          table();
        } else {
          setLoader(false);
          new throwError(response.data.message);
        }
      } else {
        setLoader(false);
        notifyError("Something went wrong");
      }
    } catch (error) {
      setLoader(false);
      notifyError(error.response.data.message);
    } finally {
      onHide();
    }
  };

  const notifyTopRight = () => {
    toast.success(`✅ Created Successfully.`, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  };
  const notifyError = (error) => {
    toast.error(`❌${error}`, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Modal className="modal fade" show={show} onHide={onHide} centered>
        <div className="" role="document">
          <div className="">
            <form>
              <div className="modal-header">
                <h4 className="modal-title fs-20">Add Details</h4>
              </div>
              <div className="modal-body">
                <i
                  className="flaticon-cancel-12 close"
                  data-dismiss="modal"
                ></i>
                <div className="add-contact-box">
                  <div className="add-contact-content">
                    <div className="form-group mb-3">
                      <label className="text-black font-w500">Name</label>
                      <div className="contact-name">
                        <input
                          type="text"
                          className="form-control"
                          autocomplete="off"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                          placeholder="Enter name"
                        />
                        {errors.name && (
                          <div className="text-danger fs-12">{errors.name}</div>
                        )}
                      </div>
                    </div>
                    <div className="form-group mb-3">
                      <label className="text-black font-w500">Email</label>
                      <div className="contact-name">
                        <input
                          type="email"
                          className="form-control"
                          autocomplete="off"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          placeholder="Enter email"
                        />
                        {errors.email && (
                          <div className="text-danger fs-12">
                            {errors.email}
                          </div>
                        )}
                      </div>
                    </div>
                    {/* <div className="form-group mb-3">
                      <label className="text-black font-w500">Slug</label>
                      <div className="contact-name">
                        <input
                          type="slug"
                          className="form-control"
                          autocomplete="off"
                          value={slug}
                          onChange={(e) => setSlug(e.target.value)}
                          placeholder="Enter Slug"
                        />
                        {errors.slug && (
                          <div className="text-danger fs-12">
                            {errors.slug}
                          </div>
                        )}
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => onSubmit()}
                >
                  Submit
                </button>
                <button
                  type="button"
                  onClick={() => onHide()}
                  className="btn btn-danger"
                >
                  {" "}
                  <i className="flaticon-delete-1"></i> Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
        {loader && <Spinner />}
      </Modal>
    </>
  );
}
