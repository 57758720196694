import React from "react";

const ImageField = ({
  value,
  width,
  height,
  alt = "portfolio Image",
  isMultiSelect,
}) => {
  return (
    <div
      style={{
        display: "flex",
        gap: "20px",
        marginTop: "20px",
        marginBottom: "20px",
        flexWrap: "wrap",
        overflow: "auto",
        height: "200px",
      }}
    >
      {isMultiSelect &&
        Array.isArray(value) &&
        value.map((item, index) => (
          <img
            src={item?.image}
            alt={alt}
            width={width}
            height={height}
            className={"portfolioImage"}
            style={{
              objectFit: "cover",
              borderRadius: "5px",
            }}
          />
        ))}
      {!isMultiSelect && (
        <img
          src={value}
          alt={alt}
          width={width}
          height={height}
          className={"portfolioImage"}
          style={{
            objectFit: "cover",
            borderRadius: "5px",
          }}
        />
      )}
    </div>
  );
};

export default ImageField;
