import React, { useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { loadingToggleAction } from "../../store/actions/AuthActions";
import logotext from "../../images/WhatsApp_Image_2023-05-17_at_8.40 1.svg";
import { login, saveTokenInLocalStorage } from "../../services/AuthService";

function Login(props) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState({ email: "", password: "" });
  const [apiError, setApiError] = useState(null);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  const validateForm = () => {
    let isValid = true;
    const errorObj = { email: "", password: "" };

    if (!email) {
      errorObj.email = "Email is required";
      isValid = false;
    }
    if (!password) {
      errorObj.password = "Password is required";
      isValid = false;
    } else if (password.length < 6) {
      errorObj.password = "Password must be at least 6 characters long";
      isValid = false;
    } else if (password.length > 15) {
      errorObj.password =
        "Password must be less than or equal to 15 characters long";
      isValid = false;
    }

    setErrors(errorObj);
    return isValid;
  };

  const onLogin = async (e) => {
    e.preventDefault();
    setApiError(null);

    if (!validateForm()) return;
    setLoading(true);
    try {
      dispatch(loadingToggleAction(true));
      const response = await login(email, password);
      saveTokenInLocalStorage(response.data.value.token);
      history.push("/dashboard");
    } catch (error) {
      setApiError(
        error.response?.data.message || "An error occurred during login"
      );
      dispatch(loadingToggleAction(false));
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="login-form-bx">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-6 col-md-7 box-skew d-flex">
            <div className="authincation-content">
              <div className="mb-4">
                <h3 className="mb-1 font-w600">Welcome to Tald</h3>
                <p>Sign in by entering information below</p>
              </div>
              {apiError && (
                <div
                  role="alert"
                  className="fade alert-dismissible fade show alert alert-danger show"
                >
                  {apiError}
                </div>
              )}
              {props.successMessage && (
                <div className="bg-green-300 text-green-900 border border-green-900 p-1 my-2">
                  {props.successMessage}
                </div>
              )}
              <form onSubmit={onLogin}>
                <div className="form-group">
                  <label className="mb-2">
                    <strong>Email</strong>
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  {errors.email && (
                    <div className="text-danger fs-12">{errors.email}</div>
                  )}
                </div>
                <div className="form-group">
                  <label className="mb-2">
                    <strong>Password</strong>
                  </label>
                  <input
                    type="password"
                    className="form-control"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  {errors.password && (
                    <div className="text-danger fs-12">{errors.password}</div>
                  )}
                </div>
                <div className="form-row d-flex justify-content-between mt-4 mb-2">
                  <div className="form-group">
                    <div className="custom-control custom-checkbox ml-1">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="basic_checkbox_1"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="basic_checkbox_1"
                      >
                        Remember my preference
                      </label>
                    </div>
                  </div>
                </div>
                <div className="text-center">
                  <button
                    type="submit"
                    className="btn btn-block btn-color"
                    disabled={loading}
                  >
                    {loading ? "Loading..." : "Sign In"}
                  </button>
                </div>
              </form>
            </div>
          </div>
          <div className="col-lg-6 col-md-5 d-flex box-skew1">
            <div className="inner-content align-self-center">
              <Link to="#" className="login-logo">
                <img src={logotext} alt="Logo" className="logo-text ml-1" />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    errorMessage: state.auth.errorMessage,
    successMessage: state.auth.successMessage,
    showLoading: state.auth.showLoading,
  };
};

export default connect(mapStateToProps)(Login);
