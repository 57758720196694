import React, { useState, useEffect } from "react";
import { Card, Form, Nav, Tab } from "react-bootstrap";
import {
  getInteriorProfile,
  getInteriorProfileStats,
  updateInteriorProfile,
} from "../../services/InteriorDesigner/InteriorDesignerService";
import { Link } from "react-router-dom";
import { CgSpinner } from "react-icons/cg";
import "./spinner.css";
import ConfirmModal from "../components/Modal/Confirm";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { Button, Typography, Box } from "@mui/material";
import Loading from "../components/common/Loading";
import InputFieldProvider from "../components/common/InputFieldProvider";
import { FormProvider, useForm } from "react-hook-form";
import "./interiorDesignerProfile.css";
import z from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { clientLeadSettingsFormInputFields } from "../../lib/common";
import Portfolio from "../components/common/Portfolio";
import ProfilesSaved from "../components/common/ProfilesSaved";
import toast from "react-hot-toast";
import { Toaster } from "react-hot-toast";
import { ToastContainer } from "react-toastify";

const basicDetailFormSchema = z.object({
  firstName_edit: z.string().min(1, "First name is required"),
  lastName_edit: z.string().min(1, "Last name is required"),
  email_edit: z.string().min(1, "Email address is required"),
});

export default function InteriorDesignerProfile(props) {
  const location = useLocation();
  const interiorDesignerId = location.pathname.split("/")[2];

  const [type, setType] = useState("");
  const [loading, setLoading] = useState(true);
  const [interiorProfile, setInteriorProfile] = useState({});
  const [isModal, setIsModal] = useState(false);
  const [apiError, setApiError] = useState("");
  const [interiorProfileStats, setInteriorProfileStats] = useState({});
  const schema = basicDetailFormSchema;
  const methods = useForm({
    resolver: zodResolver(schema),
  });
  const [isBasicDetailEditing, setIsBasicDetailEditing] = useState(false);
  const [isWorkDetailsEditing, setIsWorkDetailsEditing] = useState(false);
  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [instagram, setInstagram] = useState();
  const [companyName, setCompanyName] = useState();
  const [basicLocation, setBasicLocation] = useState();
  const [pinterest, setPinterest] = useState();
  const [about, setAbout] = useState();
  const [seenPublication0, setSeenPublication0] = useState();
  const [seenPublication1, setSeenPublication1] = useState();
  const [seenPublication2, setSeenPublication2] = useState();
  const [seenPublication3, setSeenPublication3] = useState();
  const [seenPublication4, setSeenPublication4] = useState();

  const {
    // eslint-disable-next-line no-unused-vars
    setValue,
  } = methods;
  const setFormValues = (data) => {
    setValue("virtualConsultations", data.virtualConsultations);
    setValue("needHelp", data.needHelp);
    setValue("fullServiceClients", data.fullServiceClients);
    setValue("projectType", data.projectType);
    setValue("destinationProject", data.destinationProject);
    setValue("projectSize", data.projectSize);
    setValue("minBudget", data.minBudget);
    setValue("maxBudget", data.maxBudget);
    setValue("feeStructure", data.feeStructure);
    setValue("styles", data.styles);
    setValue("preferences", data.preferences);
    setValue("saveProfiles", data.saveprofiles);
    setName(data.name);
    setEmail(data.email);
    setInstagram(data.instagramLink);
    setCompanyName(data.companyName);
    setBasicLocation(data.address);
    setPinterest(data.pinterestLink);
    setAbout(data.about);
    setSeenPublication0(data.asSeenIn.publication0);
    setSeenPublication1(data.asSeenIn.publication1);
    setSeenPublication2(data.asSeenIn.publication2);
    setSeenPublication3(data.asSeenIn.publication3);
    setSeenPublication4(data.asSeenIn.publication4);
  };
  const getInteriorData = () => {
    setLoading(true);
    Promise.all([
      getInteriorProfile(interiorDesignerId),
      getInteriorProfileStats(interiorDesignerId),
    ])
      .then((res) => {
        if (res[0].data.statusCode === 200) {
          setInteriorProfile(res[0].data.data);
          setFormValues(res[0].data.data);
        } else {
          setApiError(res.data.message);
        }
        if (res[1].data.statusCode === 200) {
          setInteriorProfileStats(res[1].data.data);
        }
        setLoading(false);
      })
      .catch((err) => {
        setApiError(err.message);
      });
  };

  const onSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    const data = Object.fromEntries(formData);

    const updatedApiData = {
      name: interiorProfile?.name,
      email: interiorProfile?.email,
      about: interiorProfile?.about,
      asSeenIn: interiorProfile?.asSeenIn,
      address: interiorProfile?.address,
      companyName: interiorProfile?.companyName,
      instagramLink: interiorProfile?.instagramLink,
      pinterestLink: interiorProfile?.pinterestLink,
      destinationProject: interiorProfile?.destinationProject,
      maxBudget: interiorProfile?.maxBudget,
      preferences: interiorProfile?.preferences,
      projectSize: interiorProfile?.projectSize,
      projectType: interiorProfile?.projectType,
      styles: interiorProfile?.styles,
      feeStructure: interiorProfile?.feeStructure,
      virtualConsultations: interiorProfile?.virtualConsultations,
      fullServiceClients: interiorProfile?.fullServiceClients,
      needHelp: interiorProfile?.needHelp,
    };
    try {
      let sendData = {};
      sendData = {
        ...updatedApiData,
        _id: interiorDesignerId,
        virtualConsultations: {
          answer: "Yes",
          charge_30_mins: data["virtualConsultations_edit.charge_30_mins"],
          charge_60_mins: data["virtualConsultations_edit.charge_60_mins"],
        },
        locationCoordinates: [[75.09807, 30.2324]],
      };

      await updateInteriorProfile(sendData);
      toast.success(`✅ Updated Successfully.`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    } catch (error) {
      toast.error(`❌${error}`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } finally {
      setIsWorkDetailsEditing(false);
    }
  };

  useEffect(() => {
    getInteriorData();
  }, [interiorDesignerId]);

  const clientLeadSettingsForm = (disabled) => {
    return (
      <>
        <div className={`basicDetailFormInputField`}>
          {clientLeadSettingsFormInputFields.map((field, index) => {
            if (disabled) {
              field.showLabel = true;
            }
            return (
              <Box key={index} marginBottom="30px">
                <InputFieldProvider disabled={disabled} {...field} />
              </Box>
            );
          })}
          {!disabled && (
            <Button
              type="submit"
              className="primaryOutline"
              variant="contained"
              sx={{ marginTop: "15px !important", width: "186px" }}
            >
              <Typography variant="body1">Save</Typography>
            </Button>
          )}
        </div>
      </>
    );
  };

  const handleEditClick = () => {
    setIsBasicDetailEditing(true);
  };

  const handleBasicDetailSaveClick = async () => {
    const {
      location,
      availability,
      stripe,
      subscription,
      webflowItemId,
      password,
      weeklySchedule,
      isBlocked,
      type,
      isSignUp,
      isApproved,
      isIndefinitely,
      saveProfiles,
      numberOfSaveImage,
      numberOfSaveProfiles,
      totalRevenue,
      isSubscription,
      isPayment,
      isDeleted,
      isVerify,
      saveImages,
      createdAt,
      updatedAt,
      __v,
      inviteeTimeType,
      inviteesSchedule,
      email: originalEmail,
      ...interiorProfileWithoutLocation
    } = interiorProfile;

    const updatedData = {
      ...interiorProfileWithoutLocation,
      name,
      email,
      instagramLink: instagram,
      companyName,
      address: basicLocation,
      pinterestLink: pinterest,
      about,
      asSeenIn: {
        publication0: seenPublication0,
        publication1: seenPublication1,
        publication2: seenPublication2,
        publication3: seenPublication3,
        publication4: seenPublication4,
      },
      locationCoordinates: [[75.09807, 30.2324]],
    };

    try {
      const response = await updateInteriorProfile(updatedData);

      if (response && response.status === 200) {
        toast.success("Profile updated successfully", {
          position: "top-right",
          duration: 5000,
        });
        setInteriorProfile(response.data.data);
      } else {
        toast.error(response.data.message || "Failed to update profile", {
          position: "top-right",
          duration: 5000,
        });
      }
    } catch (error) {
      const errorMessage =
        error.response?.data?.message ||
        error.message ||
        "Failed to update profile";
      toast.error(errorMessage, {
        position: "top-right",
        duration: 5000,
      });
    } finally {
      setIsBasicDetailEditing(false);
    }
  };

  const handleBasicDetailCancelClick = () => {
    setIsBasicDetailEditing(false);
  };

  return (
    <FormProvider {...methods}>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div className="d-flex justify-content-between">
        <div
          className="page-titles"
          data-aos="fade-right"
          data-aos-duration="1500"
          data-aos-delay="500"
        >
          <h4>Interior Designer Profile</h4>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/interior-designer-management" className="font-italic">
                Interior Designer Management
              </Link>
            </li>
            <li className="breadcrumb-item">
              <Link className="font-italic">Interior Designer Profile</Link>
            </li>
            <li className="breadcrumb-item active">
              <Link className="font-italic">
                {loading ? (
                  <CgSpinner className="spinner ml-4" size={25} />
                ) : (
                  interiorProfile.name
                )}
              </Link>
            </li>
          </ol>
        </div>
        <div
          className="mr-4"
          data-aos="fade-left"
          data-aos-duration="1500"
          data-aos-delay="500"
        >
          {interiorProfile?.isApproved !== undefined &&
            !interiorProfile?.isApproved && (
              <Link
                variant="primary"
                type="button"
                className="btn btn-color px-2 py-2 text-white"
                onClick={() => setIsModal(true)}
              >
                Approve
              </Link>
            )}
        </div>
      </div>
      <div className="row">
        <div className="col-xl-3 col-xxl-3 col-lg-6 col-sm-6">
          <div className="widget-stat card bg-theme">
            <div className="card-body p-4">
              <div className="media">
                <span className="mr-3">
                  <i className="flaticon-381-user-7"></i>
                </span>
                <div className="media-body text-black text-right">
                  <p className="mb-1">Virtual consultation</p>
                  <h4 className="text-black">
                    {interiorProfileStats?.virtualConsultation}
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-xxl-3 col-lg-6 col-sm-6">
          <div className="widget-stat card bg-theme">
            <div className="card-body p-4">
              <div className="media">
                <span className="mr-3">
                  <i className="flaticon-381-user-7"></i>
                </span>
                <div className="media-body text-black text-right">
                  <p className="mb-1">Project Inquiry</p>
                  <h4 className="text-black">
                    {interiorProfileStats?.projectInqury}
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-xl-3 col-xxl-3 col-lg-6 col-sm-6">
          <div className="widget-stat card bg-theme">
            <div className="card-body p-4">
              <div className="media">
                <span className="mr-3">
                  <i className="flaticon-381-user-7"></i>
                </span>
                <div className="media-body text-black text-right">
                  <p className="mb-1">Pending virtual consultations</p>
                  <h4 className="text-black">
                    {interiorProfileStats?.pendingVC}
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-xxl-3 col-lg-6 col-sm-6">
          <div
            className="widget-stat card bg-theme cursor-pointer"
            onClick={() => {
              props.history.push(
                `/interior-designer-management/${interiorDesignerId}/consultation`
              );
            }}
          >
            <div className="card-body p-4">
              <div className="media">
                <span className="mr-3">
                  <i className="flaticon-381-user-7"></i>
                </span>
                <div className="media-body text-black text-right">
                  <p className="mb-1">Booked virtual consultations</p>
                  <h4 className="text-black">
                    {interiorProfileStats?.bookedVC}
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-xl-3 col-xxl-3 col-lg-6 col-sm-6">
          <div className="widget-stat card bg-theme">
            <div className="card-body p-4">
              <div className="media">
                <span className="mr-3">
                  <i className="flaticon-381-user-7"></i>
                </span>
                <div className="media-body text-black text-right">
                  <p className="mb-1">Total profile saved</p>
                  <h4 className="text-black">
                    {interiorProfile?.saveProfiles?.length}
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-xl-3 col-xxl-3 col-lg-6 col-sm-6">
          <div className="widget-stat card bg-theme">
            <div className="card-body p-4">
              <div className="media">
                <span className="mr-3">
                  <i className="flaticon-381-user-7"></i>
                </span>
                <div className="media-body text-black text-right">
                  <p className="mb-1">Total images saved</p>
                  <h4 className="text-black">
                    {interiorProfileStats?.totalSavedImgCnt}
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-xxl-3 col-lg-6 col-sm-6">
          <div className="widget-stat card bg-theme">
            <div className="card-body p-4">
              <div className="media">
                <span className="mr-3">
                  <i className="flaticon-381-user-7"></i>
                </span>
                <div className="media-body text-black text-right">
                  <p className="mb-1">Revenue from virtual consultations</p>
                  <h4 className="text-black">$0</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Card>
        <Card.Header
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <Card.Title as="h5">Basic Details</Card.Title>
          <Button onClick={handleEditClick} disabled={isBasicDetailEditing}>
            Edit
          </Button>
        </Card.Header>
        <Card.Body className="d-block">
          <div className="row d-flex justify-content-between align-items-center">
            <div className="col-6 d-flex justify-content-between align-items-center ">
              <div className="">
                <p className="text-black">Name</p>
                <p className="text-black">Email</p>
                <p className="text-black">Instagram</p>
                <p className="text-black">Subscription Start Date</p>
              </div>
              <div className="">
                <p className="">
                  {loading ? (
                    <CgSpinner className="spinner" size={20} />
                  ) : (
                    <Form.Control
                      type="text"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      disabled={!isBasicDetailEditing}
                      style={{
                        height: "20px",
                        width: "250px",
                        backgroundColor: isBasicDetailEditing
                          ? ""
                          : "transparent",
                      }}
                    />
                  )}
                </p>
                <p className="">
                  {loading ? (
                    <CgSpinner className="spinner" size={20} />
                  ) : (
                    <Form.Control
                      type="text"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      disabled={!isBasicDetailEditing}
                      style={{
                        height: "20px",
                        width: "250px",
                        backgroundColor: isBasicDetailEditing
                          ? ""
                          : "transparent",
                      }}
                    />
                  )}
                </p>
                <p className="text-start">
                  {loading ? (
                    <CgSpinner className="spinner" size={20} />
                  ) : (
                    <Form.Control
                      type="text"
                      value={instagram}
                      onChange={(e) => setInstagram(e.target.value)}
                      disabled={!isBasicDetailEditing}
                      style={{
                        height: "20px",
                        width: "250px",
                        backgroundColor: isBasicDetailEditing
                          ? ""
                          : "transparent",
                      }}
                    />
                  )}
                </p>
                <p className="text-start">
                  {loading ? (
                    <CgSpinner className="spinner" size={20} />
                  ) : (
                    interiorProfile.createdAt
                  )}
                </p>
              </div>
            </div>
            <div className="col-6 d-flex justify-content-between align-items-center">
              <div className="">
                <p className="text-black">Company Name</p>
                <p className="text-black">Location</p>
                <p className="text-black">Pinterest</p>
                <p className="text-black">Subscription End Date</p>
              </div>
              <div className="">
                <p className="">
                  {loading ? (
                    <CgSpinner className="spinner" size={20} />
                  ) : (
                    <Form.Control
                      type="text"
                      value={companyName}
                      onChange={(e) => setCompanyName(e.target.value)}
                      disabled={!isBasicDetailEditing}
                      style={{
                        height: "20px",
                        width: "250px",
                        backgroundColor: isBasicDetailEditing
                          ? ""
                          : "transparent",
                      }}
                    />
                  )}
                </p>
                <p className="">
                  {loading ? (
                    <CgSpinner className="spinner" size={20} />
                  ) : (
                    <Form.Control
                      type="text"
                      value={basicLocation}
                      onChange={(e) => setBasicLocation(e.target.value)}
                      disabled={!isBasicDetailEditing}
                      style={{
                        height: "20px",
                        width: "250px",
                        backgroundColor: isBasicDetailEditing
                          ? ""
                          : "transparent",
                      }}
                    />
                  )}
                </p>
                <p className="text-start">
                  {loading ? (
                    <CgSpinner className="spinner" size={20} />
                  ) : (
                    <Form.Control
                      type="text"
                      value={pinterest}
                      onChange={(e) => setPinterest(e.target.value)}
                      disabled={!isBasicDetailEditing}
                      style={{
                        height: "20px",
                        width: "250px",
                        backgroundColor: isBasicDetailEditing
                          ? ""
                          : "transparent",
                      }}
                    />
                  )}
                </p>
                <p className="text-start">
                  {loading ? (
                    <CgSpinner className="spinner" size={20} />
                  ) : (
                    interiorProfile.updatedAt
                  )}
                </p>
              </div>
            </div>
          </div>
          <p className="text-black">Bio</p>
          <div className="text-left bio-border pl-5">
            <p className="">
              {loading ? (
                <CgSpinner className="spinner" size={20} />
              ) : (
                <Form.Control
                  as="textarea"
                  value={about}
                  onChange={(e) => setAbout(e.target.value)}
                  disabled={!isBasicDetailEditing}
                  style={{
                    height: "160px",
                    backgroundColor: isBasicDetailEditing ? "" : "transparent",
                  }}
                />
              )}
            </p>
          </div>
          <p className="text-black">As Seen In</p>
          <p className="text-black">
            Enter the names of up to five (5) publications where your firm has
            been featured. If you leave this section blank, it simply will not
            show on your profile.
          </p>
          <div className="as-seen-in">
            {loading ? (
              <CgSpinner className="spinner" size={20} />
            ) : (
              <>
                <Form.Control
                  type="text"
                  value={seenPublication0}
                  placeholder="Publication 1"
                  onChange={(e) => setSeenPublication0(e.target.value)}
                  disabled={!isBasicDetailEditing}
                  style={{
                    height: "20px",
                    width: "100%",
                    minWidth: "500px",
                    backgroundColor: isBasicDetailEditing ? "" : "transparent",
                  }}
                />
                <Form.Control
                  type="text"
                  value={seenPublication1}
                  onChange={(e) => setSeenPublication1(e.target.value)}
                  disabled={!isBasicDetailEditing}
                  placeholder="Publication 2"
                  style={{
                    height: "20px",
                    width: "100%",
                    minWidth: "500px",
                    backgroundColor: isBasicDetailEditing ? "" : "transparent",
                  }}
                />
                <Form.Control
                  type="text"
                  value={seenPublication2}
                  onChange={(e) => setSeenPublication2(e.target.value)}
                  disabled={!isBasicDetailEditing}
                  placeholder="Publication 3"
                  style={{
                    height: "20px",
                    width: "100%",
                    minWidth: "500px",
                    backgroundColor: isBasicDetailEditing ? "" : "transparent",
                  }}
                />
                <Form.Control
                  type="text"
                  value={seenPublication3}
                  onChange={(e) => setSeenPublication3(e.target.value)}
                  disabled={!isBasicDetailEditing}
                  placeholder="Publication 4"
                  style={{
                    height: "20px",
                    width: "100%",
                    minWidth: "500px",
                    backgroundColor: isBasicDetailEditing ? "" : "transparent",
                  }}
                />
                <Form.Control
                  type="text"
                  value={seenPublication4}
                  onChange={(e) => setSeenPublication4(e.target.value)}
                  disabled={!isBasicDetailEditing}
                  placeholder="Publication 5"
                  style={{
                    height: "20px",
                    width: "100%",
                    minWidth: "500px",
                    backgroundColor: isBasicDetailEditing ? "" : "transparent",
                  }}
                />
              </>
            )}
          </div>
        </Card.Body>
        <Card.Footer style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            onClick={handleBasicDetailSaveClick}
            disabled={!isBasicDetailEditing}
          >
            Save
          </Button>
          <Button
            onClick={handleBasicDetailCancelClick}
            disabled={!isBasicDetailEditing}
          >
            Cancel
          </Button>
        </Card.Footer>
      </Card>
      <Card>
        <Card.Body>
          <div className="custom-tab-1">
            <Tab.Container defaultActiveKey="home">
              <Nav as="ul" className="nav-tabs">
                <Nav.Item as="li">
                  <Nav.Link eventKey="home" onClick={() => setType("all")}>
                    Work Details
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item as="li">
                  <Nav.Link
                    eventKey="portfolio"
                    onClick={() => setType("active")}
                  >
                    Portfolio
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item as="li">
                  <Nav.Link
                    eventKey="saveProfiles"
                    onClick={() => setType("active")}
                  >
                    Profiles Saved
                  </Nav.Link>
                </Nav.Item>
              </Nav>
              <Tab.Content className="pt-4">
                <Tab.Pane eventKey="home">
                  <div className={`accountBox mb-30`}>
                    <div
                      className={`flexBetween`}
                      style={{ marginBottom: "10px!important" }}
                    >
                      <Typography
                        variant="h3"
                        marginBottom={3}
                        style={{ fontSize: "1.5rem" }}
                      >
                        Client Lead Settings
                      </Typography>
                      {!isWorkDetailsEditing && (
                        <span
                          className="cursor-pointer"
                          onClick={() => {
                            setIsWorkDetailsEditing(true);
                            // setSelectedForm(forms.projectDetails);
                            // setSchema(forms.projectDetails.schema);
                          }}
                          style={{
                            textDecoration: "underline",
                            textUnderlineOffset: "5px",
                          }}
                        >
                          {loading ? "" : "Edit"}
                        </span>
                      )}
                    </div>
                    {loading ? (
                      <Loading isSkeleton />
                    ) : (
                      <form onSubmit={onSubmit}>
                        {clientLeadSettingsForm(!isWorkDetailsEditing)}
                      </form>
                    )}
                  </div>
                </Tab.Pane>
              </Tab.Content>
              <Tab.Content>
                <Tab.Pane eventKey="portfolio">
                  <div className="d-flex" style={{ gap: "1rem" }}>
                    {loading ? (
                      <CgSpinner className="spinner" size={20} />
                    ) : (
                      <Portfolio interiorDesignerId={interiorDesignerId} />
                    )}
                  </div>
                </Tab.Pane>
              </Tab.Content>
              <Tab.Content>
                <Tab.Pane eventKey="saveProfiles">
                  <div className="d-flex" style={{ gap: "1rem" }}>
                    {loading ? (
                      <CgSpinner className="spinner" size={20} />
                    ) : (
                      <ProfilesSaved interiorProfile={interiorProfile} />
                    )}
                  </div>
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </div>
        </Card.Body>
      </Card>
      <ConfirmModal
        show={isModal}
        userId={interiorDesignerId}
        onHide={() => setIsModal(false)}
      />
      <Toaster />
    </FormProvider>
  );
}
