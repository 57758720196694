import instance from "../Instance";
import { APPROVE_USER, GET_REQUESTS } from "./RequestsEndpoints";

export function getRequests(currentPage, limit) {
  const data = localStorage.getItem("taldDetails");
  const myHeaders = {
    Authorization: `Bearer ${data}`,
  };
  return instance.get(GET_REQUESTS + `?page=${currentPage}&limit=${limit}`, {
    headers: myHeaders,
  });
}

export function approveUser(requestId, status) {
  const postData = {
    requestId,
    status,
  };
  const data = localStorage.getItem("taldDetails");
  const myHeaders = {
    Authorization: `Bearer ${data}`,
  };
  return instance.put(APPROVE_USER, postData, {
    headers: myHeaders,
  });
}

