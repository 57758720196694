import React, { useState } from "react";
import { Box, Grid, Rating, Typography, Button } from "@mui/material";
import moment from "moment";
import { getNickName } from "../../../lib/common";
import clockIcon from "../../../images/clock.svg";
// import ImageScrollSection from "@/components/molecules/ImageScrollSection";

const PastConsultationCard = ({ consultation }) => {
    const [showMore, setShowMore] = useState(false);
    return (
        <>
            <Grid item xs={12} key={consultation._id}>
                <Box
                    sx={{
                        mx: "-5px",
                        width: '100%',
                        borderRadius: "14px",
                        padding: "24px",
                        border: "0.6px solid #333333",
                        boxShadow: "0px 18px 64px 0px rgba(40, 55, 55, 0.05)",
                    }}
                >
                    <Box
                        sx={{
                            fontSize: "24px",
                            color: "#333",
                            mb: "10px",
                        }}
                    >
                        {consultation?.user?.name}
                    </Box>

                    {/* <p className='fz-18 font-bold mb-20'>
            {`Book another slot with ${consultation.designer.name} > `}
          </p> */}
                    <div>
                        <Box className={`flexEndWrap gap-10 mb-10`} sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
                            <img src={clockIcon} alt="Clock" />
                            <Box
                                sx={{
                                    borderRadius: "29px",
                                    background: "rgba(241, 240, 238, 0.6)",
                                    padding: "10px 20px",
                                    whiteSpace: "nowrap",
                                    fontSize: "14px",
                                }}
                            >
                                {moment(consultation?.confirmSlotTime).format("MMM DD, YYYY, hh:mm A")}
                            </Box>
                        </Box>
                        <Box
                            sx={{
                                mt: "15px",
                                mb: "10px",
                                fontSize: "18px",
                            }}
                        >
                            Overview
                        </Box>
                        <Box
                            sx={{
                                border: "1px solid #333",
                                padding: "12px 10px",
                                color: "#333",
                                textTransform: "capitalize",
                            }}
                        >
                            {consultation?.projectSummary}
                        </Box>
                        {/* <div className={` ${styles.attachmentsSecWrap} ${styles.uploadRowFull} mb-10`}>
              <div className={styles.uploaderRow}>
                <ImageScrollSection />
              </div>
            </div> */}
                    </div>
                    <div>
                        {consultation?.isPast && !consultation?.isCancel && Boolean(consultation?.review?.rating) && Boolean(consultation?.user?._id) && (
                            <>
                                <Typography
                                    variant="h4"
                                    sx={{
                                        fontSize: "24px",
                                        mt: "20px",
                                        mb: "20px",
                                    }}
                                >
                                    User Review
                                </Typography>
                                <Box
                                    sx={{
                                        textAlign: "center",
                                        padding: "25px 15px",
                                        borderRadius: "10px",
                                        border: "1px solid rgba(0,0,0,0.01)",
                                        backgroundColor: "rgba(0,0,0,0.01)",
                                    }}
                                >
                                    <Box
                                        sx={{
                                            fontSize: "14px",
                                            marginBottom: "10px",
                                            width: "40px",
                                            height: "40px",
                                            borderRadius: "50%",
                                            display: "flex",
                                            color: "#fff",
                                            mx: "auto",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            backgroundColor: "#333",
                                        }}
                                    >
                                        {getNickName(consultation?.user?.name ?? "")}
                                    </Box>

                                    <Box sx={{ fontSize: "14px", marginBottom: "10px" }}>{consultation?.user?.name}</Box>

                                    <Box sx={{ mb: "10px" }}>
                                        <Rating value={consultation?.review?.rating ?? 0} readOnly />
                                    </Box>
                                    <Box
                                        sx={{
                                            lineHeight: "1.5 !important",
                                        }}
                                    >
                                        {consultation?.review?.comment
                                            ? consultation?.review?.comment?.length > 150 && !showMore
                                                ? consultation?.review?.comment.substring(0, 150) + "..."
                                                : consultation?.review?.comment
                                            : ""}
                                    </Box>
                                    {consultation?.review?.comment && consultation?.review?.comment?.length > 150 && (
                                        <Button
                                            type="submit"
                                            className="primaryOutline"
                                            variant="contained"
                                            sx={{
                                                mt: "20px",
                                                backgroundColor: "#333",
                                                "&:hover": {
                                                    background: "#333",
                                                },
                                            }}
                                            onClick={() => setShowMore(!showMore)}
                                        >
                                            <Typography
                                                variant="body1"
                                                sx={{
                                                    whiteSpace: "nowrap",
                                                }}
                                            >
                                                {!showMore ? "Show More" : "Show Less"}
                                            </Typography>
                                        </Button>
                                    )}
                                </Box>
                            </>
                        )}
                    </div>
                </Box>
            </Grid>
        </>
    );
};

export { PastConsultationCard };
