import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { Typography } from "@mui/material";
import "./customDialogStyle.css";

const CustomDialog = ({ open, onClose, title, content, action, className }) => {
  return (
    <Dialog
      className={`authPopupWrap authPopupWrap ${className}`}
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>
        <Typography variant="h3" style={{ fontSize: "2rem" }}>
          {title}
        </Typography>
        <IconButton
          edge="end"
          color="inherit"
          onClick={onClose}
          aria-label="close"
          sx={{
            position: "absolute",
            right: "50px!important",
            top: "34px",
            borderRadius: "50%",
            border: "1px solid #333",
            padding: "4px",
            "& svg": {
              width: "18px",
              height: "18px",
              fill: "#333",
            },
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent
        sx={{
          overflow: "hidden",
          height: "fit-content",
          paddingRight: "20px",
          padding:
            className === "deletePopupDialog" ? "0px !important" : undefined,
        }}
      >
        {content}
      </DialogContent>
      {action && <DialogActions sx={{ px: 3 }}>{action}</DialogActions>}
    </Dialog>
  );
};

export default CustomDialog;
