import React, { useState, useEffect } from "react";
import {
  Card,
  Col,
  Dropdown,
  Table,
  Button,
  Modal,
  Form,
} from "react-bootstrap";
import PageTitle from "../layouts/PageTitle";
import Spinner from "../components/common/Spinner";
import { ToastContainer, toast } from "react-toastify";
import moment from "moment";
import Pagination from "../components/common/Pagination";
import {
  getCouponsList,
  createCoupon,
  updateCoupon,
  deleteCoupon,
} from "../../services/coupons/CouponsService";

const svg1 = (
  <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <rect x="0" y="0" width="24" height="24"></rect>
      <circle fill="#000000" cx="5" cy="12" r="2"></circle>
      <circle fill="#000000" cx="12" cy="12" r="2"></circle>
      <circle fill="#000000" cx="19" cy="12" r="2"></circle>
    </g>
  </svg>
);

const Coupons = () => {
  const [loader, setLoader] = useState(false);
  const [coupons, setCoupons] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [search, setSearch] = useState("");
  const [addModal, setAddModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [currentCoupon, setCurrentCoupon] = useState(null);
  const [newCoupon, setNewCoupon] = useState({ name: "", discount: "" });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchCoupons();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, search]);

  const fetchCoupons = async () => {
    setLoader(true);
    try {
      const response = await getCouponsList(currentPage, 10, search);

      if (Array.isArray(response.data.data)) {
        setCoupons(response.data.data);
      } else {
        setCoupons([]);
      }

      setPageCount(Math.ceil(response.total / 10));
      setLoader(false);
    } catch (error) {
      toast.error("Error fetching coupons");
      setLoader(false);
    }
  };

  const handleAddCoupon = async () => {
    if (newCoupon.name && newCoupon.discount) {
      setLoading(true);
      try {
        await createCoupon(newCoupon);
        toast.success("Coupon added successfully!");
        setAddModal(false);
        setNewCoupon({ name: "", discount: "" });
        fetchCoupons();
      } catch (error) {
        toast.error("Error adding coupon");
      } finally {
        setLoading(false);
      }
    } else {
      toast.error("Please fill in all fields");
    }
  };

  const handleOpenEditModal = (coupon) => {
    setCurrentCoupon(coupon);
    setNewCoupon({
      name: coupon.name,
      discount: coupon.percent_off || (coupon.amount_off / 100).toString(),
    });
    setEditModal(true);
  };

  const handleUpdateCoupon = async () => {
    setLoading(true);
    try {
      await updateCoupon({
        couponId: currentCoupon.id,
        name: newCoupon.name,
      });
      toast.success("Coupon updated successfully!");
      fetchCoupons();
      setEditModal(false);
    } catch (error) {
      toast.error("Error updating coupon");
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteCoupon = async (couponId) => {
    try {
      await deleteCoupon(couponId);
      toast.success("Coupon deleted successfully!");
      fetchCoupons();
    } catch (error) {
      toast.error("Error deleting coupon");
    }
  };

  const filteredCoupons = coupons.filter((coupon) =>
    coupon.name.toLowerCase().includes(search.toLowerCase())
  );

  return (
    <div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <PageTitle activeMenu="Coupons List" motherMenu="Coupons" />
      <Col>
        <Card>
          <Card.Header>
            <div className="row d-flex justify-content-end align-items-center">
              <div className="col-4" style={{ flexGrow: 1 }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div
                    className="input-group border bg-white input-group-sm"
                    style={{ borderRadius: "8px" }}
                  >
                    <input
                      style={{
                        paddingBottom: "25px",
                        paddingTop: "25px",
                        borderRadius: "10px",
                        fontSize: "14px",
                      }}
                      type="text"
                      name="table_search"
                      className="form-control float-right border-0"
                      placeholder="Search"
                      onChange={(e) => setSearch(e.target.value)}
                    />
                    <div className="input-group-append">
                      <button type="button" className="btn btn-default">
                        <i className="fa fa-search" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <Button
                variant="primary"
                type="button"
                className="btn btn-color px-2 text-white"
                onClick={() => setAddModal(true)}
              >
                Add New +
              </Button>
            </div>
          </Card.Header>
          <Card.Body>
            <Table responsive>
              <thead style={{ color: "black" }}>
                <tr>
                  <th>
                    <strong>COUPON NAME</strong>
                  </th>
                  <th>
                    <strong>DISCOUNT</strong>
                  </th>
                  <th>
                    <strong>CREATION DATE</strong>
                  </th>
                  <th>
                    <strong>ACTION</strong>
                  </th>
                </tr>
              </thead>
              <tbody>
                {filteredCoupons.map((item) => (
                  <tr key={item.id}>
                    <td>{item.name}</td>
                    <td>
                      {item.percent_off
                        ? `${item.percent_off}%`
                        : item.amount_off
                        ? `$${(item.amount_off / 100).toFixed(2)}`
                        : "No Discount"}
                    </td>
                    <td>{moment.unix(item.created).format("MM/DD/YYYY")}</td>
                    <td>
                      <Dropdown>
                        <Dropdown.Toggle
                          variant="secondary light"
                          className="light sharp btn btn-secondary i-false"
                        >
                          {svg1}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item
                            onClick={() => handleOpenEditModal(item)}
                          >
                            Edit
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() => handleDeleteCoupon(item.id)}
                          >
                            Delete
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            {filteredCoupons.length === 0 && !loader ? (
              <div className="justify-content-center d-flex my-5 ">
                Sorry, No Coupons Found!
              </div>
            ) : (
              ""
            )}
            <Pagination
              pageCount={pageCount}
              pageValue={currentPage}
              setPage={setCurrentPage}
            />
          </Card.Body>
        </Card>
      </Col>
      {loader && <Spinner />}

      {/* Add Coupon Modal */}
      <Modal
        show={addModal}
        onHide={() => setAddModal(false)}
        dialogClassName="modal-dialog-centered"
      >
        <Modal.Header closeButton>
          <Modal.Title>Add New Coupon</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group>
              <Form.Label>Coupon Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter coupon name"
                value={newCoupon.name}
                onChange={(e) =>
                  setNewCoupon({ ...newCoupon, name: e.target.value })
                }
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Discount (%)</Form.Label>
              <div className="input-group">
                <Form.Control
                  type="number"
                  placeholder="Enter discount percentage"
                  value={newCoupon.discount}
                  onChange={(e) => {
                    let discount = e.target.value;
                    if (discount > 100) discount = 100;
                    setNewCoupon({ ...newCoupon, discount });
                  }}
                  max="100"
                />
                <div className="input-group-append">
                  <span className="input-group-text">%</span>
                </div>
              </div>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setAddModal(false)}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={handleAddCoupon}
            disabled={loading}
          >
            {loading ? "Adding..." : "Add Coupon"}
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Edit Coupon Modal */}
      <Modal
        show={editModal}
        onHide={() => setEditModal(false)}
        dialogClassName="modal-dialog-centered"
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Coupon</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group>
              <Form.Label>Coupon Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter coupon name"
                value={newCoupon.name}
                onChange={(e) =>
                  setNewCoupon({ ...newCoupon, name: e.target.value })
                }
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setEditModal(false)}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={handleUpdateCoupon}
            disabled={loading}
          >
            {loading ? "Updating..." : "Update Coupon"}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Coupons;
