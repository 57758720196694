import React, { useState } from "react";
import {
  Button,
  FormControl,
  Menu,
  Radio,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  FormGroup,
} from "@mui/material";
import { useFormContext, Controller } from "react-hook-form";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const CustomDropdown = ({
  label,
  name,
  options,
  defaultValue,
  isMultiSelect = false, // Default to single-select mode
  menuListWidth,
  disabled = false,
}) => {
  const { control } = useFormContext();
  const [anchorEl, setAnchorEl] = (useState < null) | (HTMLElement > null);
  // const [menuWidth, setMenuWidth] =
  //   (useState < number) | ("auto" > (anchorEl ? anchorEl.clientWidth : "auto")); // Initialize width
  const [menuWidth, setMenuWidth] = useState(
    anchorEl ? anchorEl.clientWidth : "auto"
  );
  const handleButtonClick = (event) => {
    setAnchorEl(event.currentTarget);
    setMenuWidth(event.currentTarget.clientWidth);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className="dropdownBtnMenu">
      <FormControl fullWidth>
        <Button
          aria-controls={`${name}-menu`}
          aria-haspopup="true"
          onClick={handleButtonClick}
          variant="contained"
          color="primary"
          className="primaryOutline btn-sm btn-menu"
          fullWidth
        >
          {label} <KeyboardArrowDownIcon />
        </Button>
        <Controller
          name={name}
          control={control}
          defaultValue={defaultValue}
          render={({ field, fieldState: { error, invalid } }) => (
            <>
              <Menu
                id={`${name}-menu`}
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                slotProps={{
                  paper: {
                    style: {
                      width: menuListWidth || menuWidth,
                      maxHeight: "400px", // Optional: Set a maximum height for the menu
                    },
                  },
                }}
              >
                {isMultiSelect && <FormLabel>Select Multiple</FormLabel>}
                <FormGroup>
                  {options &&
                    options?.map((option) => (
                      <FormControlLabel
                        key={typeof option === "string" ? option : option.value}
                        control={
                          <Radio
                            disabled={disabled}
                            checked={
                              isMultiSelect
                                ? field.value.includes(
                                    typeof option === "string"
                                      ? option
                                      : option.value
                                  )
                                : field.value ===
                                  (typeof option === "string"
                                    ? option
                                    : option.value)
                            }
                            onChange={() => {
                              if (isMultiSelect) {
                                // Multi-select mode: Toggle selected options
                                const optionValue =
                                  typeof option === "string"
                                    ? option
                                    : option.value;
                                const updatedValue = field.value.includes(
                                  optionValue
                                )
                                  ? field.value.filter(
                                      (value) => value !== optionValue
                                    )
                                  : [...field.value, optionValue];

                                field.onChange(updatedValue);
                              } else {
                                // Single-select mode: Set the selected option
                                const optionValue =
                                  typeof option === "string"
                                    ? option
                                    : option.value;
                                field.onChange(optionValue);
                                handleClose(); // Close the menu after selecting in single-select mode
                              }
                            }}
                          />
                        }
                        label={
                          typeof option === "string" ? option : option.label
                        }
                      />
                    ))}
                </FormGroup>
              </Menu>
              {invalid && (
                <FormHelperText error>{error?.message}</FormHelperText>
              )}
            </>
          )}
        />
      </FormControl>
    </div>
  );
};

export default CustomDropdown;
