import instance from "../Instance";
import {
  GET_LIST,
  USERS_ACTION,
  CREATE_VENDOR,
  GET_PROFILE,
  GET_INTERIOR_DESIGNER_PROFILE,
  UPDATE_VENDOR_PROFILE,
  GET_VENDOR_PORTFOLIO,
  RESEND_TEMP_PASSWORD,
  GET_CONSULTATION_DETAILS,
} from "./ApiEndpoints";

export function getInteriorList(currentPage, limit, search) {
  const data = localStorage.getItem("taldDetails");
  const myHeaders = {
    Authorization: `Bearer ${data}`,
  };
  return instance.get(GET_LIST + `?page=${currentPage}&limit=${limit}&search=${search}`, {
    headers: myHeaders,
  });
}

export function getInteriorProfile(interiorId) {
  const data = localStorage.getItem("taldDetails");
  const myHeaders = {
    Authorization: `Bearer ${data}`,
  };
  return instance.get(GET_PROFILE + `?id=${interiorId}`, {
    headers: myHeaders,
  });
}

export function updateInteriorProfile(sendData) {
  const data = localStorage.getItem("taldDetails");
  const myHeaders = {
    Authorization: `Bearer ${data}`,
  };
  return instance.put(
    UPDATE_VENDOR_PROFILE,
    { ...sendData },
    {
      headers: myHeaders,
    }
  );
}

export function getInteriorProfileStats(interiorId) {
  const data = localStorage.getItem("taldDetails");
  const myHeaders = {
    Authorization: `Bearer ${data}`,
  };
  return instance.get(GET_INTERIOR_DESIGNER_PROFILE + `?id=${interiorId}`, {
    headers: myHeaders,
  });
}

export function getInteriorProfilePortfolio(interiorId) {
  const data = localStorage.getItem("taldDetails");
  const myHeaders = {
    Authorization: `Bearer ${data}`,
  };
  return instance.get(GET_VENDOR_PORTFOLIO + `?userId=${interiorId}`, {
    headers: myHeaders,
  });
}

export function userAction(userId, key) {
  const data = localStorage.getItem("taldDetails");
  const myHeaders = {
    Authorization: `Bearer ${data}`,
  };

  return instance.put(
    USERS_ACTION,
    { userId, key },
    {
      headers: myHeaders,
    }
  );
}

export function createInterior({ email, name }) {
  // export function createInterior({ email, name, slug }) {
  const data = localStorage.getItem("taldDetails");
  const myHeaders = {
    Authorization: `Bearer ${data}`,
  };

  return instance.post(
    CREATE_VENDOR,
    { email, name },
    // { email, name, slug },
    {
      headers: myHeaders,
    }
  );
}

export function resendTempPassword(email) {
  const data = localStorage.getItem("taldDetails");
  const myHeaders = {
    Authorization: `Bearer ${data}`,
  };

  return instance.post(
    RESEND_TEMP_PASSWORD,
    { email },
    {
      headers: myHeaders,
    }
  );
}

export function getConsultationDetails({ page = 0, id, limit = 0 }) {
  const data = localStorage.getItem("taldDetails");
  const myHeaders = {
    Authorization: `Bearer ${data}`,
  };

  return instance.get(`${GET_CONSULTATION_DETAILS}/?page=${page}&limit=${limit}&designerId=${id}`, {
    headers: myHeaders,
  });
}
